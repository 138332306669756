<script lang="jsx">

import ClientOnly from 'vue-client-only'

export default {
  name: 'ColorTitle',
  functional: true,
  components: {
    ClientOnly,
  },
  props: {
    isShowColorMoreIcon: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default: () => ({})
    },
    colorBlockText: {
      type: String,
      default: ''
    },
    supportLargeImageList: {
      type: Boolean,
      default: false
    },
    isShowFindMyShadeEntry: {
      type: Boolean,
      default: false
    },
    largeImageSwitch: {
      type: Function,
      default: () => {}
    },
    clickShowFindMyShade: {
      type: Function,
      default: () => {}
    },
  },
  render(h, { props }) {
    const {
      isShowColorMoreIcon,
      language,
      colorBlockText,
      supportLargeImageList,
      isShowFindMyShadeEntry,
      largeImageSwitch,
      clickShowFindMyShade,
    } = props

    // 渲染大图模式切换下拉提示
    const renderSupprotLargeImagesListTips = () => {
      if (!supportLargeImageList || !isShowColorMoreIcon) {
        return null
      }
      return (
        <i
          class="suiiconfont sui_icon_more_down_16px"
        ></i>
      )
    }

    const renderFindMyShadeEntry = () => {
      if (!isShowFindMyShadeEntry) {
        return null
      }

      return (
        <a
          da-event-click="1-6-4-31"
          href="javascript:;"
          class="goods-color__shade"
          vOn:click={() => { clickShowFindMyShade() }}
        >{ language.SHEIN_KEY_PWA_18952 }</a>
      )
    }

    return (
      <div class="goods-color__title">
        <div
          class="selected-color"
          vOn:click={() => { largeImageSwitch() }}
        >
          <span class="color-block">{ colorBlockText }</span>
          { renderSupprotLargeImagesListTips() }
          <span>{ language.SHEIN_KEY_PWA_23118 }</span>
        </div>

        <ClientOnly>
          { renderFindMyShadeEntry() }
        </ClientOnly>
      </div>
    )
  }
}

</script>

<style lang="less">
.goods-color {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .selected-color {
      display: inline-flex;
      align-items: center;
      line-height: 1;
      margin-bottom: 4px;
    }

    .color-block, i {
      display: inline-block;
      font-style: normal;
      font-weight: 700;
      .font-dpr(28px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x: hidden;
      max-width: 8.9333rem;

      /* rw:begin */
      font-family: Adieu;
    }
  }

  &__shade {
    display: inline-block;
    color: #1860A7;
    .font-dpr(24px);
    text-decoration: none;
  }
}
</style>
