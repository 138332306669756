<script lang="jsx">
import { mapGetters, mapMutations } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import schttp from 'public/src/services/schttp'

import CommentOverview from './CommentOverview.vue'
import TitleSellPoint from './TitleSellPoint.vue'

daEventCenter.addSubscriber({ modulecode: '1-6-1' })

export default {
  name: 'GoodsNameV2',
  components: {
    CommentOverview,
    ShareDrawer: () => import('public/src/pages/goods_detail_v2/innerComponents/drawer/ShareDrawer.vue'),
  },
  data() {
    return {
      is_show_more_icon: false,
      is_title_ellipsis: true,
      is_lock: false,
      is_load_share: false,
      show_share_drawer: false,
      goods_link: '',
      is_touch_moved_sellpoint: false,
    }
  },
  computed: {
    //TODO: 旧状态，收尾后删除
    ...mapGetters('newProductDetail/common', ['allDataReady']),
    // --end
    ...mapGetters('productDetail/GoodsName', [
      'is_black',
      'goods_name',
      'goods_name_comment_info',
      'goods_name_labels',
      'init_share_url',
      'pinterest_img',
      'titlesellpoint_abt',
      'title_sell_points_list',
    ]),
    ...mapGetters('productDetail/common', [
      'goods_id',
      'language_v2',
      'common_page_info',
    ]),
    calcGoodsNameStatus() {
      return this.allDataReady && {
        goods_name: this.goods_name,
        goods_name_labels: this.goods_name_labels,
        goods_name_comment_info: this.goods_name_comment_info,
      }
    },
  },
  watch: {
    calcGoodsNameStatus(val) {
      if (val) {
        this.calcGoodsNameHeight()
      }
    },
    goods_id() {
      if (typeof window !== 'undefined') {
        this.is_title_ellipsis = true
        this.is_touch_moved_sellpoint = false
      }
    },
  },
  mounted() {
    window.vBus?.$on?.('handleHeaderShareForGoodsDetail', this.showShareChannel)
  },
  beforeDestroy() {
    window.vBus?.$off?.('handleHeaderShareForGoodsDetail', this.showShareChannel)
  },
  methods: {
    //TODO: 旧状态，收尾后删除
    ...mapMutations('newProductDetail/CommentPopup', ['openViewCommentPop']),
    // --end

    calcGoodsNameHeight() {
      if (typeof window === 'undefined') return
      this.is_show_more_icon = false
      this.$nextTick(() => {
        const descHeight = this.$refs?.goodsDetailTitle?.offsetHeight
        // 超两行省略出现下拉按钮（含标签lineheight:16， 纯文本lineheight:14）
        this.is_show_more_icon = descHeight > 35
      })
    },
    toogleGoodsNameEllipsis() {
      this.is_title_ellipsis = !this.is_title_ellipsis
    },
    async showShareChannel() {
      // TODO: 分享弹窗
      if (this.is_lock || typeof window === 'undefined') return 
      const { currency, appLanguage, SiteUID } = gbCommonInfo
      this.is_lock = true
      this.is_load_share = true
      this.goods_link = this.init_share_url + encodeURIComponent('?share_from=' + SiteUID)
      if (isLogin()) {
        // TODO: 登录后获取URL
        const formData = new FormData()
        formData.append('pageType', 1)
        formData.append('shareUrl', this.init_share_url)
        const jsonInfo = await schttp({
          method: 'POST',
          url: '/marketing/gmtc/shareLink',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
            'AppCurrency': currency,
            'AppLanguage': appLanguage
          },
          useBffApi: true
        })
        if (jsonInfo?.info?.url?.indexOf('url_from') > -1) {
          this.goods_link = encodeURIComponent(decodeURIComponent(jsonInfo.info.url) + '&share_from=' + SiteUID)
        }
      }
      this.show_share_drawer = true
      this.is_lock = false
    },
    closeSharePopup() {
      this.show_share_drawer = false
    },
    openReview() {
      daEventCenter.triggerNotice({
        daId: '1-6-1-121',
        extraData: {
          from: 1
        }
      })
      this.openViewCommentPop()
    },
    touchMoveSellpoint() {
      // 商品标签sellpoint滑动上报
      if(!this.is_touch_moved_sellpoint){
        this.is_touch_moved_sellpoint = true
        daEventCenter.triggerNotice({
          daId: '1-6-1-226',
        })
      }
    },
  },
  render() {
    const { 
      language_v2 = {},
      is_black = false,
      is_show_more_icon = false,
      is_title_ellipsis = true,
      titlesellpoint_abt = false,
      goods_name = '',
      goods_name_labels = [],
      goods_name_comment_info = {},
      is_load_share = false,
      show_share_drawer = false,
      goods_link = '',
      pinterest_img = '',
      title_sell_points_list = [],
      common_page_info = {},
    } = this
    const { PUBLIC_CDN = '' } = common_page_info || {}
    const getLabelAnalysisData = (key) => {
      return {
        directives: [
          {
            name: 'expose',
            value: {
              id: '1-6-1-222',
              data: { key },
              code: 'expose—title-label-events',
            }
          }
        ]
      }
    }
    const renderLabelList = () => {
      return goods_name_labels.map(label => {
        const { key, value, bgColor, textColor, imgInfo, qualityImg } = label || {}
        const { imgUrl } = imgInfo || {}
        if (key === 'preferredSeller') {
          return <span
            {...{ directives: [{ name: 'expose', value: { id: '1-6-1-99' } }] }}
            aria-hidden="true"
            class="goods-name__label goods-name__preferred-seller"
          >
            <div class="seller-icon">
              <img src={`${PUBLIC_CDN}/pwa_dist/images/store/store_preferred_seller-bb758e2772.png`} />
            </div>
            {value}
          </span> 
        }
        if (key === 'new') {
          return <span
            {...getLabelAnalysisData('new')}
            aria-hidden="true"
            class="goods-name__label"
            style={{
              color: textColor,
              background: bgColor,
            }}
          >
            {value}
          </span>
        }
        if (key === 'trend') {
          return <span
            {...getLabelAnalysisData('trend')}
            class="goods-name__fashionIcon"
          >
            {imgUrl ? 
              <img class="goods-name__fashionIcon-img" src={imgUrl} /> :
              <span class="goods-name__fashionIcon-default">
                {language_v2?.SHEIN_KEY_PWA_30166 || 'Trends'}
              </span>
            }
          </span>
        }
        if (key === 'quality') {
          return <span class="goods-name__choices">
            <img class="goods-name__choices-img" src={qualityImg} />
          </span>
        }
        if (key === 'evolushein') {
          return <span
            aria-hidden="true"
            class="goods-name__label goods-name__evolushein"
          >
            {value}
          </span>
        }
        if (key === 'brand') {
          return <span
            aria-hidden="true"
            class="goods-name__label goods-name__brand"
          >
            {value}
          </span>
        }
        return null
      })
    }
    const renderNewDown = () => {
      if (!is_show_more_icon) return null
      if (titlesellpoint_abt && is_title_ellipsis) {
        return  <span class="sellpoint-icon sellpoint-icon_down">
          <i
            class="sui_icon_more_down_12px_2 suiiconfont"
          ></i>
        </span>
      }
      return null
    }
    const renderFoldIcon = () => {
      if (!is_show_more_icon) return null
      if (titlesellpoint_abt) {
        return !is_title_ellipsis ? (<span class="sellpoint-icon sellpoint-icon_up">
          <i
            class="sui_icon_more_up_12px_2 suiiconfont"
          ></i>
        </span>) : null
      }
      return <i
        class={[is_title_ellipsis ? 'sui_icon_more_down2_16px' : 'sui_icon_more_up2_16px', 'suiiconfont', 'title-expand-icon']}
      ></i>
    }
    const renderName = () => {
      return <span fsp-key={goods_name} class={[
        'detail-title-text', 
        'fsp-element', 
        titlesellpoint_abt ? 'title-text-bold' : ''
      ]} 
      role="text" 
      tabindex="1" 
      aria-label={goods_name}
      >
        {goods_name}
      </span>
    }
    const renderComment = () => {
      const { 
        show_goods_name_comment,
        commentNumShow,
        comment_rank_average,
      } = goods_name_comment_info || {}
      if (!show_goods_name_comment) return null
      return <CommentOverview 
        commentNumShow={commentNumShow}
        commentRankAverage={comment_rank_average}
        language={language_v2}
        openReview={this.openReview}
      />
    }
    const renderShareDrawer = () => {
      if (!is_load_share) return null
      const getChannelAnalysis = ({ type, channel }, goodsId) => {
        const data = {
          share_type: 'page',
          content_id: goodsId,
          channel,
        }
        return {
          id: type === 'click' ? '2-24-4' : '2-24-3',
          code: 'ShareCommonChannel',
          once: false,
          data,
        }
      }
      return  <ShareDrawer
        showShareDrawer={show_share_drawer}
        goodsLink={goods_link}
        publicCdnRp={PUBLIC_CDN}
        pinterestImg={pinterest_img}
        getChannelAnalysis={getChannelAnalysis}
        vOn:closeSharePopup={this.closeSharePopup}
      />
    }
    return <div class="NEW_BFF_COMPONENT">
      <div
        class={['goods-name', { 'goods-name__black': is_black }]}>
        <h1 
          ref="goodsDetailTitle"
          class={['detail-title-h1', {
            'new-line-height': goods_name_labels?.length > 0,
            'title-line-camp': is_show_more_icon && is_title_ellipsis,
            'title-line-sellpoint': titlesellpoint_abt && is_show_more_icon,
            'title-line-old': !(titlesellpoint_abt && is_show_more_icon),
          }]}
          vOn:click={this.toogleGoodsNameEllipsis}
        >
          {renderLabelList()}
          {renderNewDown()}
          {renderName()}
          {renderFoldIcon()}
        </h1>
        {renderComment()}
        {renderShareDrawer()}
      </div>
      {title_sell_points_list?.length ? 
        <TitleSellPoint  
          sellPointsList={title_sell_points_list}
          vOn:touchMoveSellpoint={this.touchMoveSellpoint}
        /> : null}
    </div>
  }
}
</script>

<style lang="less">
.goods-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.2133rem;
    width: 100%;

    &__comment {
        align-self: flex-start;
        margin-left: 0.32rem;
    }

    &__label {
        display: inline-block;
        margin-right: 0.11rem;
        padding: 0 0.08rem;
        height: 16px;
        line-height: 16px;
        text-align: center;
        border-radius: 0.05333rem;
        font-size: 11px;
        /*rw:begin*/
        padding: 0 5px;
        font-family: 'Adieu';
        font-weight: 700;
    }

    &__preferred-seller {
      position: relative;
      display: inline-flex;
      align-items: center;
      background: #fff8eb;
      color: @sui_color_micro_emphasis;
      height: 14px;
      line-height: 14px;
      border: .5px solid rgba(168, 97, 4, 0.15);
      border-radius: 2px;
      padding: 0 3/37.5rem;
      padding-left: 17px;
      white-space: nowrap;
      font-size: 10px;
      font-family: 'SF-UI-Text-Regular';
      .seller-icon {
        position: absolute;
        top: 0px;
        left: 3px;
        width: 12px;
        height: 12px;
        /* stylelint-disable-next-line */
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    &__fashionIcon, &__choices {
        display: inline-block;
        height: 16px;
        line-height: 16px;
        margin-right: 0.11rem;

        img {
            height: 100%;
        }
    }

    &__fashionIcon-img, &__choices-img {
        vertical-align: top;
    }

    &__fashionIcon-default {
        position: relative;
        display: inline-block;
        padding: 0 5px 0 2px;
        font-size: 11px;
        font-style: italic;
        font-weight: 858;
        color: #fff;
        height: 100%;
        background-image: url(https://img.ltwebstatic.com/images3_ccc/2024/10/11/5a/1728647656e1875c401ca2ff6b22f6e8e4f4aab806.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    &__evolushein {
        background: linear-gradient(85deg, #FFEEDA -7.76%, #FFF2E1 39.69%, #FFF3E6 97.47%);
        color: #A86104;
        text-transform: none; // 取消首字母大写
    }

    &__brand {
        color: #FFF;
        font-weight: 510;
        background: linear-gradient(90deg, #749CD8 -1.43%, #9BBAEA 100%);
        text-transform: none;
    }

    >h1 {
        position: relative;
        .font-dpr(24px);
        color: #666;
        line-height: 14px;
        user-select: text;
        vertical-align: middle;
        font-weight: normal;

        .detail-title-text {
            word-break: break-all;
        }

        @supports not (word-break: break-all) {
          .detail-title-text {
              word-break: break-word;
          }
        }

        .title-text-bold {
            font-weight: 510;
            color: #161616;
        }

        &.new-line-height {
            line-height: 16px;
        }

        .title-expand-icon {
            position: absolute;
            bottom: -3px;
            right: 0px;
            background: #fff;
            box-shadow: -10px 0px 10px 3px #fff;
        }
    }

    .title-line-old.title-line-camp {
        .line-camp();
    }

    .title-line-sellpoint.title-line-camp {
        max-height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: justify;
    }

    .title-line-sellpoint {
        &::before {
            content: '';
            float: right;
            height: 14px;
        }

        .sellpoint-icon {
            width: 14px;
            text-align: center;
            color: #000;
        }

        .sellpoint-icon_down {
            float: right;
            clear: both;
            position: relative;
            margin-left: 0.26666rem;

            &::before {
                content: '...';
                position: absolute;
                left: 0;
                color: #333;
                transform: translateX(-100%)
            }
        }

        .sellpoint-icon_up {
            vertical-align: middle;
            display: inline-block;
        }
    }

    .new-line-height.title-line-camp {
        max-height: 32px;

    }

    .new-line-height.title-line-sellpoint {
        &::before {
            content: '';
            float: right;
            height: 16px;
        }

        .sellpoint-icon_up {
            vertical-align: baseline;
        }
    }

    .sui_icon_share_20px {
        vertical-align: middle;
        .txt-r();
        width: auto;
        height: auto;
        color: #222;
        margin-left: 0.32rem;
    }

    &__black {
        >h1 {
            color: #222;
        }
    }
}
</style>
