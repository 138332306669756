<script lang="jsx">
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import Sizes from 'public/src/pages/components/sizes/index.vue'
import { getGoodsUrl } from 'public/src/pages/goods_detail/utils/common.js'
import { queryString } from 'public/src/pages/common/utils/index.js'

export default {
  name: 'SizeBox',
  computed: {
    ...mapState('newProductDetail/common', [
      'currentMySize', 
      'currentMyBraSize', 
      'externalSizeInfoIndex', 
      'externalSkuCode', 
      'addToBagForm'
    ]),
    ...mapGetters('newProductDetail', ['isSsr']),
    ...mapGetters('newProductDetail/common', [
      'currentCountry',
      'current',
      'isOnSale',
      'isSoldOut',
      'mallCode',
      'goodsId',
      'productRelationID',
      'allDataReady',
      'detail',
      'currentMall',
      'currentMallSkuStocks',
      'currentMall'
    ]),
    ...mapGetters('newProductDetail/SizeBox', [
      'sizeComposeData',
      'skuMapCompose',
      'recommendCombo',
      'sizeConstant',
      'currentUnit',
      'sizeConfig',
      'tipsCombo',
      'isOneSize',
    ]),
    ...mapGetters('newProductDetail', ['fsAbt']), // 新数据流
    sizeGuidPlanA(){
      // return this.fsAbt?.sizeguideyouhua520?.param?.sizeguideyouhua520 === 'planA'
      return true
    },
  },
  watch: {
    detail(val, oldVal) {
      if (val?.goods_id !== oldVal?.goods_id) {
        const isNewSpu = val?.productRelationID !== oldVal?.productRelationID
        this.$nextTick(() => {
          this.updateHeelHeightStatus(false) // 这个状态会影响跟高显示
          this.$refs['SIZE_BOX'] && this.$refs['SIZE_BOX'].resetSizeBox(true, isNewSpu)
        })
      }
    },
    currentMySize(val) {
      this.$refs['SIZE_BOX'] && this.$refs['SIZE_BOX'].sizeValue(val)
    },
    currentMyBraSize(val) {
      this.$refs['SIZE_BOX'] && this.$refs['SIZE_BOX'].sizeValue(val)
    },
    allDataReady(val) {
      if (val) {
        // TODO: 后续优化
        this.$refs['SIZE_BOX'] && this.$refs['SIZE_BOX']?.pointReport?.()
      }
    },
  },
  mounted() {
    this.initSizeBox()

    setTimeout(() => {
      this.markTopContentAsReady()
    }, 100)
  },
  methods: {

    ...mapMutations('newProductDetail/common', [
      'updateFromSwitchColor',
      'updateCurrentLocalCountry',
      'updateExternalSkuCode',
      'updateExternalSizeInfoIndex',
      'updateAddToBagForm',
      'updateSkuInfo',
      'updateQuickShip',
      'updateQuickAddState',
    ]),
    ...mapMutations('newProductDetail/SizeBox', [
      'updateRealMallStock',
      'updateSwitchMallCode',
      'updateGlobalPerfectFitShow',
      'updateHeelHeightStatus',
    ]),
    ...mapActions('newProductDetail/SizeBox', [
      'initSizeBoxState'
    ]),
    // TODO 这里后面要统一替换 ⬇️
    // ...mapMutations('productDetail', ['changePageStatus']),
    ...mapActions('newProductDetail', [
      'openSizeGuidePop',
      'openSizeRecommendPop',
      'openBraSizeRecommendPop',
      'markTopContentAsReady'
    ]),
    // TODO 这里后面要统一替换 ⬆️
    initSizeBox() {
      this.initSizeBoxState()
      this.updateLocalCountry()
    },
    updateLocalCountry() {
      this.$nextTick(() => {
        const country = window.localStorage.getItem('last_select_country')
        if (!country) return
        window.localStorage.setItem('cache_last_select_country', country || '')
        this.updateCurrentLocalCountry(country === 'default' ? '' : country)
      })
    },
    handleLocalChange(val) {
      const { isNewSizeLocal } = this.sizeConfig
      isNewSizeLocal && this.updateCurrentLocalCountry(val)
    },
    clickToBuyHandle() {
      // TODO 这里后面要统一替换
      // this.changePageStatus({ quickAddState: true })
      this.updateQuickAddState(true)
    },
    mallClickHandle(mallInfo) {
      const { mallCode } = mallInfo
      this.updateSwitchMallCode(mallCode)
      this.updateRealMallStock(null)
    },
    openSizeGuideHandle(scene = '', fromScene = '') {
      this.openSizeGuidePop({ fromScene }) // TODO yidier
      if (scene !== 'SizeSlideTips') return
      // daEventCenter.triggerNotice({
      //   daId: '1-8-1-31',
      //   extraData: {},
      // })
    },
    handleOpenQuickCart(item = {}) {
      const { goodsId } = item
      this.$quickAdd.open({
        goods_id: goodsId,
        addSource: 'detailPage',
        isShowAttrPlusSize: false,
        showBestDealLabel: true,
        showFollowBeltByOrigin: true,
        showEstimatedPrice: true,
        hideRelationGoods: true,
        hideSizeGroupeSize: true,
        analysisConfig: {
          sa: {
            activity_from: 'sizegroup',
            location: 'page'
          },
          style: 'detail'
        },
        clickCallBack: {
          // 加车成功后的回调
          complete: () => {
            setTimeout(() => {
              window.vBus &&
                window.vBus.$emit('triggerAddCompletedFloatCart', {
                  animation: false
                })
            }, 2000)
          }
        }
      })
    },
    handleClickSizeGroupItem(item){
      const { findPerfectFitScene } = item
      if(findPerfectFitScene) return this.handleOpenQuickCart(item)
      if (this.goodsId === item.goodsId) return // 当前商品不做操作
      const mallCode = this.mallCode
      const { langPath  } = this.sizeConstant

      // TODO MYTEST 这里需要注意 暂时先用SwitchColor 后续当前需求优化后再改
      this.updateFromSwitchColor(true)

      // this.updateAttriBute(''),
      // this.updateMainAttribute(this.colorBoxInfo.mainAttribute)
      let search = location.search.replace(/&?(attr_ids|main_attr|mallCode|isFromSwitchColor)=(\w|_)+/g, '')
      // search = search + (search.indexOf('?') > -1 ? '&main_attr=' + this.colorBoxInfo.mainAttribute : '?main_attr=' + this.colorBoxInfo.mainAttribute)
      search = search + (search.indexOf('?') > -1 ? '&mallCode=' + mallCode : '?mallCode=' + mallCode)
      search = search + (search.indexOf('?') > -1 ? '&isFromSwitchColor=1' : '?isFromSwitchColor=1')
      let url = `${langPath}${getGoodsUrl(item.goodsName, item.goodsId)}` + search
      this.$router.replace(url)
    },
    handlePerfectFitShowStatus(status){
      this.updateGlobalPerfectFitShow(status)
    },
    handleCompose({ attrs, skuInfo, externalSizeInfoIndex, curSelectAttr, mallStock }) {
      const addToBagForm = this.addToBagForm
      addToBagForm.attrs = attrs
      this.updateRealMallStock(mallStock)
      this.updateSkuInfo(skuInfo)
      this.updateAddToBagForm(addToBagForm)
      this.updateExternalSizeInfoIndex(externalSizeInfoIndex)

      if (typeof window !== 'undefined') {
        window.vBus &&
          window.vBus.$emit('changeWakeupData', {
            sku_code: skuInfo?.sku_code || '',
            selected_attr_value_list: attrs.map((_) => String(_.attr_value_id)) || [],
          })
      }
      if (curSelectAttr?.attr_value_name) {
        daEventCenter.triggerNotice({
          daId: '1-6-1-33',
          target: {
            dataset: {
              attr_id: curSelectAttr.attr_id,
              attr_value_id: curSelectAttr.attr_value_id || '',
              from: 'detailPage',
              attr_value: curSelectAttr.attr_value_name,
              sku_code: skuInfo?.sku_code,
              location: 'page',
            },
          },
        })
      }
    },
    handleSizeRecommend() {
      const { ruleType } = this.recommendCombo
      // 鞋子
      if (ruleType == 0) {
        this.openSizeRecommendPop()
      }
      // 内衣
      if (ruleType == 1) {
        this.openBraSizeRecommendPop()
      }
    },
    handleUpdateQuickShip(status) {
      this.updateQuickShip(status)
    },
    handleSizeReady() {
      const { skucode = '' } = queryString.parse(location.search)
      if (skucode ) {
        this.updateExternalSkuCode(skucode)
      }
    }
  },
  render() {
    // call back
    const {
      handleLocalChange,
      clickToBuyHandle,
      mallClickHandle,
      openSizeGuideHandle,
      handleCompose,
      handleSizeRecommend,
      handleUpdateQuickShip,
    } = this

    const {
      sizeComposeData,
      isOnSale,
      isSoldOut,
      currentUnit,
      sizeConfig,
      sizeConstant,
      currentCountry,
      mallCode,
      currentMall,
      currentMallSkuStocks,
      externalSkuCode,
      externalSizeInfoIndex,
      tipsCombo,
      recommendCombo,
      skuMapCompose,
      isOneSize,
      isSsr,
    } = this

    const {
      baseInfo,
      saleAttrList,
      dataMapCompose,
      heightSizeMap,
      localSize,
      sizeInfoDes,
      language,
      sizeTitle,
      relatedLocalSize,
      parentCats,
      tackInfoDes,
      cccAttrGroups
    } = sizeComposeData

    const customerStyle = {
      bold: true,
      title: sizeTitle,
    }

    const { cccList = [], onlyRelatedGoods = [] } =  cccAttrGroups

    return (
      <Sizes
        ref="SIZE_BOX"
        class="goods-size_dom"
        current-mall={currentMall}
        current-mall-sku-stocks={currentMallSkuStocks}
        config={sizeConfig}
        constant={sizeConstant}
        sizeGuidPlanA={this.sizeGuidPlanA}
        customer-style={customerStyle}
        language={language}
        is-on-sale={isOnSale}
        is-sold-out={isSoldOut}
        is-one-size={isOneSize}
        base-info={baseInfo}
        ccc-attr-groups={cccList}
        ccc-only-related-goods={onlyRelatedGoods}
        sale-attr-list={saleAttrList}
        country={currentCountry}
        size-unit={currentUnit}
        sku-map-compose={skuMapCompose}
        data-map-compose={dataMapCompose}
        height-size-map={heightSizeMap}
        local-size={localSize}
        size-info-des={sizeInfoDes}
        related-local-size={relatedLocalSize}
        mall-code={mallCode}
        parent-cats={parentCats}
        tack-info-des={tackInfoDes}
        external-sku-code={externalSkuCode}
        external-size-info-index={externalSizeInfoIndex}
        tips-combo={tipsCombo}
        recommend-combo={recommendCombo}
        need-update-size={isSsr}
        fs-abt={this.fsAbt}
        sizes-ready-callback={this.handleSizeReady}
        v-on:compose={handleCompose}
        v-on:handleClickSizeGroupItem={this.handleClickSizeGroupItem}
        v-on:handlePerfectFitShowStatus={this.handlePerfectFitShowStatus}
        v-on:handleLocalChange={handleLocalChange}
        v-on:clickToBuy={clickToBuyHandle}
        v-on:mallClick={mallClickHandle}
        v-on:openSizeGuide={openSizeGuideHandle}
        v-on:handleSizeRecommend={handleSizeRecommend}
        v-on:updateQuickShip={handleUpdateQuickShip}
      />
    )
  },
}
</script>
