<template>
  <div
    id="priceContainer"
    class="productPriceContainer"
    :style="priceContainerStyle"
  >
    <FlashBeltWraper
      v-if="flash_belt_wraper_info.isFlash"
      :flash-belt-wraper-info="flash_belt_wraper_info"
    >
      <Price
        :price-from="price_from"
        :main-price-info="main_price_info"
        :price-vat-info="price_vat_info"
        :discount-info="discount_info"
        :estimated-info="estimated_info"
        :suggested-sale-price-info="suggested_sale_price_info"
        :recommend-retail-price="recommend_retail_price"
        :exclusive-info="exclusive_info"
        @clickEstimatedTag="clickEstimatedTag"
      />
    </FlashBeltWraper>
    <template v-else>
      <Price
        :price-from="price_from"
        :main-price-info="main_price_info"
        :price-vat-info="price_vat_info"
        :discount-info="discount_info"
        :estimated-info="estimated_info"
        :suggested-sale-price-info="suggested_sale_price_info"
        :recommend-retail-price="recommend_retail_price"
        :exclusive-info="exclusive_info"
        @clickEstimatedTag="clickEstimatedTag"
      />
      <EstimatedNewCountdown v-if="estimated_new_end_time" :end-time="estimated_new_end_time" />
    </template>
  </div>
</template>

<script setup name="PriceContainer">
/**
 * @file
 * price 容器 引入 vuex 数据传入 price 组件
*/
import { defineAsyncComponent, computed } from 'vue'
import { useMapGetters, useMapActions } from 'public/src/pages/goods_detail/utils/useVuex.js'
import Price from 'public/src/pages/goods_detail_v2/innerComponents/top/Price/index.vue'
const FlashBeltWraper =  defineAsyncComponent(() => import('./FlashBeltWraper/index.vue'))
const EstimatedNewCountdown =  defineAsyncComponent(() => import('public/src/pages/goods_detail_v2/innerComponents/top/Price/components/EstimatedTag/EstimatedNewCountdown.vue'))

const {
  price_from,
  main_price_info,
  price_vat_info,
  discount_info,
  estimated_info,
  suggested_sale_price_info,
  recommend_retail_price,
  exclusive_info,
  flash_belt_wraper_info,
  estimated_new_end_time
} = useMapGetters('productDetail/price', [
  'price_from',
  'main_price_info',
  'price_vat_info',
  'discount_info',
  'estimated_info',
  'suggested_sale_price_info',
  'recommend_retail_price',
  'exclusive_info',
  'flash_belt_wraper_info',
  'estimated_new_end_time'
])


const { click_estimated_tag } = useMapActions('productDetail/price', ['click_estimated_tag'])

const clickEstimatedTag = () => click_estimated_tag()

const priceContainerStyle = computed(() => {
  if (flash_belt_wraper_info.value.isFlash) return {}
  return {
    'display': 'flex',
    'justify-content': 'space-between'
  }
})


</script>

<style lang="less">
.productPriceContainer {
  width: 100%;
  margin-bottom: .2133rem;
}
</style>
