<script lang="jsx">
import { mapGetters, mapState, mapMutations } from 'vuex'
import { expose } from 'public/src/pages/common/analysis/directive.js'
import ColorTitle from './ColorTitle.vue'
import ColorV2 from './ColorV2.vue'
import { throttle } from '@shein/common-function'

export default {
  name: 'SkcPreposeBox',
  directives: { expose },
  components: {
    ColorTitle,
    ColorV2,
  },
  props: {
    fixedRatio: {
      type: String,
      default: ''
    },
    // Apollo 控制补图
    isSupportCropImage: {
      type: Boolean,
      default: false
    },
    switchColor: {
      type: Function,
      required: true
    },
  },
  data () {
    return {
      goodsImgMap: {},
      hasLeftCover: true
    }
  },
  computed: {
    ...mapGetters('newProductDetail/ColorBox', ['colorBoxInfo']),
    ...mapGetters('newProductDetail/common', ['goodsId']),
    ...mapState('newProductDetail/ColorBox', ['lastClickColor']),
    ...mapState('newProductDetail/common', ['loading']),
    getaAnaData(){
      return { 
        name: 'expose', 
        value: { 
          id: '1-6-1-31', 
          data: { 
            if_have_mainattr_icon: 0,
            if_have_switch_image_entry: 0,
            location: 'page',
            image_tp: 'small',
            is_front: 1
          }
        } 
      }
    }
  },
  watch: {
    'goodsId': {
      handler() {
        this.$nextTick(() => {
          this.colorIntoView()
        })
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (typeof window == 'undefined') return
      this.colorIntoView()
      const scrollContainer = document.getElementsByClassName('goods-color__list-box')?.[0]
      const handleScroll = throttle({
        func: () => {
          if (scrollContainer?.scrollWidth - scrollContainer?.clientWidth - scrollContainer?.scrollLeft <= 10) {
            this.hasLeftCover = false
          }else {
            this.hasLeftCover = true
          }
        },
        wait: 50
      })
 
      scrollContainer?.addEventListener('scroll', handleScroll)
    })  
  },
  methods: {
    ...mapMutations('newProductDetail/common', ['updateQuickAddState', 'updateQuickAddLargeImgMode']),
    ...mapMutations('newProductDetail/ColorBox', ['updateAllgoodsImg']),
    colorIntoView(){
      if (typeof window == 'undefined') return
      var element = document.getElementsByClassName('goods-color__prepose-item color-active')[0]
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth', // 平滑滚动
          block: 'nearest', // 最接近的边缘
          inline: 'start'
        })
      }
    },
    // 点击大图模式切换，直接打开快速加车弹窗大图列表模式
    onLargeImageSwitch() {
      const { colorBoxInfo, updateQuickAddState, updateQuickAddLargeImgMode } = this
      const { supportLargeImageList } = colorBoxInfo
      if (!supportLargeImageList) {
        return
      }
      updateQuickAddLargeImgMode(true)
      updateQuickAddState(true)

      daEventCenter.triggerNotice({ daId: '1-6-1-101' })
    },
  },
  render() {
    const {
      detail,
      language,
      colorConf,
      LAZY_IMG,
      WEB_CLIENT,
      goodsDesc,
      supportLargeImageList,
      colorType,
      colorsInfo,
      showGoodsDesc,
      IS_RW,
      PUBLIC_CDN_rp,
      hotGoodSnList,
      loadConf,
      goodsMainAttPicInfo,
    } = this.colorBoxInfo

    const renderColorSelectBox = () => {
      return (
        <ColorV2
          language={language}
          lazyImg={LAZY_IMG}
          colorConf={colorConf}
          detail={detail}
          webClient={WEB_CLIENT}
          goodsDesc={goodsDesc}
          colorsInfo={colorsInfo}
          eventCategory={'商品详情页'}
          from={'page'}
          colorType={colorType}
          supportLargeImageList={supportLargeImageList}
          showGoodsDesc={showGoodsDesc}
          isRw={IS_RW}
          publicCdn={PUBLIC_CDN_rp}
          hotGoodSnList={hotGoodSnList}
          loadConf={loadConf}
          fixedRatio={this.fixedRatio}
          isSupportCropImage={this.isSupportCropImage}
          switchColor={this.switchColor}
          handleAllgoodsImg={this.updateAllgoodsImg}
          loading={this.loading}
          lastClickColor={this.lastClickColor}
          goodsMainAttPicInfo={goodsMainAttPicInfo}
          isSkcPrepose={true}
        />
      )
    }

    return (
      <div 
        class="goods-color-prepose"
        {...{ directives: [this.getaAnaData] }}
      >
        {/* 色块主体 */}
        { renderColorSelectBox() }
        {supportLargeImageList && 
          <div 
            class="goods-color-prepose__right-arrow"
            {...{ directives: [
              { name: 'expose', value: { id: '1-6-1-79' } }, 
              { name: 'tap', value: { id: '1-6-1-80' } }] 
            }}
          >
            {this.hasLeftCover && <div class="goods-color-prepose__left-cover"></div>}
            <div class="goods-color-prepose__right-cover">
              <i class="suiiconfont sui_icon_more_right_16px"
                vOn:click={() => { this.onLargeImageSwitch() }}></i>
            </div>
          </div>}
      </div>
    )
  }
}

</script>

<style lang="less">
.goods-color-prepose {
  position: relative;
  padding-right: 0.533rem;
  margin-bottom: 0.266rem;
  .goods-color__list-box {
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__left-cover{
    background: linear-gradient(270deg, #FFF -3.13%, rgba(255, 255, 255, 0.00) 103.13%);
    width: 0.64rem;
    height: 100%
  }
  &__right-cover{
    width: 0.533rem;
    background-color: #FFF;
    height: 1.06rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #959595
  }
  &__right-arrow {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: auto;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .goods-color__imgs{
    padding: .266rem 0 .213rem;
  }

  .goods-color__soldout{
    border: 1px dashed @sui_color_gray_weak1;
  }
}
</style>
