<script lang="jsx">
const { GB_cssRight } = gbCommonInfo

export default {
  name: 'ColorV2AddTag',
  functional: true,
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    colorType: {
      type: String,
      default: 'default',
    },
    preposeColorType: {
      type: String,
      default: 'Thumbnail',
    },
    promotionTagInfos: {
      type: Object,
      default: () => ({})
    },
    labelExpose: {
      type: Function,
      default: () => {}
    },
    publicCdn: {
      type: String,
      default: ''
    },
    isLargeImage: {
      type: Boolean,
      default: false
    },
    isShowAttrNewIcon: {
      type: Boolean,
      default: false
    },
    showColorsTag: {
      type: Boolean,
      default: false
    },
  },
  render(h, { props }) {
    const { item, promotionTagInfos, labelExpose, isLargeImage, isShowAttrNewIcon, showColorsTag, colorType, preposeColorType } = props
    const { goods_id, hot_color, isEco, isNew, lowPrice } = item || {}
    const isPreposeBlock = colorType === 'prepose' && preposeColorType === 'colorBlock'
    if (!showColorsTag) return null
    /** 色块tag信息 */
    const renderColorTag = () => {
      if (promotionTagInfos && promotionTagInfos[goods_id] && promotionTagInfos[goods_id].showVisible) {
        if (promotionTagInfos[goods_id].tagType === 1 && promotionTagInfos[goods_id].unit_discount) {
          return (
            <div
              class={!isLargeImage ? 'goods-color__tag-discount' : 'tag-discount'}
              {...{ expose: [{ name: 'expose', value: labelExpose(goods_id) }] }}
            >
            -{ promotionTagInfos[goods_id].unit_discount }%
            </div>
          )
        } else if (promotionTagInfos[goods_id].tagType === 2) {
          return (
            <div
              class={!isLargeImage ? 'suiiconfont sui_icon_activity_12px goods-color__tag-promotion' : 'suiiconfont sui_icon_activity_12px tag-promotion'}
              {...{ expose: [{ name: 'expose', value: labelExpose(goods_id) }] }}
            ></div>
          )
        }
        return null
      } else {
        if (isEco === '1') {
          return (
            <img
              class={!isLargeImage ?  `goods-color__evolu ${isPreposeBlock ? 'goods-color__preColor' : ''}` : 'evolu'}
              src={GB_cssRight ? 'https://img.ltwebstatic.com/images3_ccc/2024/10/10/e8/1728547164df1a42cf52782ac9f22ecdb541a7fa1c.png' : 'https://img.ltwebstatic.com/images3_ccc/2024/09/26/4e/1727353949f470aa5d372776c6db1d0e61e5281d68.png'}
            />
          )
        } else if (isNew === '1' && isShowAttrNewIcon) {
          return (
            <img
              class={!isLargeImage ? `goods-color__new ${isPreposeBlock ? 'goods-color__preColor' : ''}` : 'new'}
              src={GB_cssRight ? 'https://img.ltwebstatic.com/images3_ccc/2024/10/11/5b/17286260770c1ce60f13a429ee051a0e7f95785650.png' : 'https://img.ltwebstatic.com/images3_ccc/2024/10/11/5b/1728626077f562513c709f3fbd10bf3167bd9dfcef.png'}
            />
          )
        } else if (lowPrice) {
          let src = GB_cssRight ?
            'https://img.ltwebstatic.com/images3_ccc/2024/10/11/4d/1728617483b9d49c429684f137dab29e356e8694c0.png' :
            'https://img.ltwebstatic.com/images3_ccc/2024/10/11/c7/1728617411040bbdfeb78852fd58a094a2180b7a49.png'
          return <img
            class={`goods-color__low  ${isPreposeBlock ? 'goods-color__preColor' : ''}`}
            src={src}
          />
        } else if (hot_color === '1') {
          return (
            <img
              class={!isLargeImage ? `goods-color__hot ${isPreposeBlock ? 'goods-color__preColor' : ''}` : 'hot'}
              src={GB_cssRight ? 'https://img.ltwebstatic.com/images3_ccc/2024/10/10/6d/1728547129831115ea4fad4b79f1caec6ca4617c24.png' : 'https://img.ltwebstatic.com/images3_ccc/2024/09/26/36/1727353722d5ac6151867d1504299e8bd37f299251.png'}
            />
          )
        }
        return null
      }
    }

    return renderColorTag()
  }
}

</script>
