<script lang="jsx">
import SizeSelectLabel from './SizeSelectLabel'
import SizeSelectRelatedLabel from './SizeSelectRelatedLabel'
import SizeGroupLabel from './SizeGroupLabel'
import SizeRecommendEntrance from './SizeRecommendEntrance'
/**
 * @props
 *  @param { Boolean } bold 标题加粗
 */
export default {
  name: 'SizeSelectItem',
  functional: true,
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    sizeInfoIndex: {
      type: Object,
      default: () => ({}),
    },
    showLocalTextType: {
      type: Number,
      default: 0,
    },
    country: {
      type: String,
      default: '',
    },
    sizeGroupPosition: {
      type: String,
      default: '',
    },
    goodsId: {
      type: String,
      default: '',
    },
    saleAttrGroups: {
      type: Object,
      default: () => ({}),
    },
    sizeRecommendInfo: {
      type: Object,
      default: () => ({}),
    },
    recommendSizeTextDom: {
      type: String,
      default: '',
    },
    sizeAttrInfo: {
      type: Object,
      default: () => ({}),
    },
    isOnSale: {
      type: Boolean,
      default: true,
    },
    hasOneSize: {
      type: Boolean,
      default: false,
    },
    loadingAsyncComp: {
      type: Boolean,
      default: false,
    },
    defaultCountryCode: {
      type: String,
      default: '',
    },
    customerStyle: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    sizeConstant: {
      type: Object,
      default: () => ({}),
    },
    handleClickLocalTextSelect: {
      type: Function,
      default: () => {},
    },
    handleSizeClick: {
      type: Function,
      default: () => {},
    },
    handleCalcSize: {
      type: Function,
      default: () => {},
    },
    handleChooseCheck: {
      type: Function,
      default: () => {},
    },
    handleOpenSizeGuideFn: {
      type: Function,
      default: () => {},
    },
    handleSizeRecommend: {
      type: Function,
      default: () => {},
    },
    handlerOpenAdd: {
      type: Function,
      default: () => {},
    },
  },
  render(h, { props }) {
    const { 
      item, 
      country,
      sizeInfoIndex,
      showLocalTextType,
      isOnSale,
      loadingAsyncComp,
      hasOneSize,
      defaultCountryCode,
      goodsId,
      sizeGroupPosition,
      saleAttrGroups,
      sizeRecommendInfo,
      recommendSizeTextDom,
      sizeAttrInfo,
      customerStyle, 
      config,
      language,
      sizeConstant,
      handleClickLocalTextSelect,
      handleSizeClick,
      handleCalcSize,
      handleChooseCheck,
      handleOpenSizeGuideFn,
      handleSizeRecommend,
      handlerOpenAdd,
    } = props || {}
    const { bold, overflowX = false } = customerStyle || {}
    const { 
      attr_name = '', 
      attr_id = '',
      isSize = '', 
      attr_value_list, 
    } = item || {}
    const showRecommendEntrance = isSize === '1' && config?.showSizeRecommend && loadingAsyncComp

    const selectLocalEl = () => {
      if (isSize !== '1') return null
      if (showLocalTextType == 3) {
        return (<span class="goods-size__standard">
          {!config.swapSizeTabPosition && <span
            class={country !== defaultCountryCode ? 'goods-size__black' : ''}
            v-on:click={()=>{
              handleClickLocalTextSelect({ notDrawer: true, chooseCountry: '' })
            }}
          >
            { language?.SHEIN_KEY_PWA_33076 || 'Standard' }
          </span>}
          <span 
            class={country === defaultCountryCode ? 'goods-size__black' : ''}
            v-on:click={()=>{
              handleClickLocalTextSelect({ notDrawer: true, chooseCountry: defaultCountryCode })
            }}
          >
            { defaultCountryCode }
          </span>
          {config.swapSizeTabPosition && <span
            class={country !== defaultCountryCode ? 'goods-size__black' : ''}
            v-on:click={()=>{
              handleClickLocalTextSelect({ notDrawer: true, chooseCountry: '' })
            }}
          >
            { language?.SHEIN_KEY_PWA_33076 || 'Standard' }
          </span>}
        </span>)
      }
      if (showLocalTextType == 2) {
        return (
          <span
            class="goods-size__title-local"
            v-on:click={handleClickLocalTextSelect}
          >
            {country || language.SHEIN_KEY_PWA_23344}
            <i
              class="suiiconfont sui_icon_more_down2_12px"
              aria-hidden="true"
            ></i>
          </span>
        )
      }
      if (showLocalTextType == 1 && country) {
        return <span>({country})</span>
      }
      return null
    }

    const itemTitleEl = () => (
      <div
        aria-hidden="true"
        class={['goods-size__title', { 'self-size-recommend': sizeRecommendInfo?.sizeRecommendType == 1 }]}
      >
        <div class={['goods-size__title-txt', { 'goods-size__title-bold': bold }]}>
          {attr_name}
          {selectLocalEl()}
        </div>
        {showRecommendEntrance ? 
          <SizeRecommendEntrance 
            sizeRecommendInfo={sizeRecommendInfo}
            recommendSizeTextDom={recommendSizeTextDom}
            goodsId={goodsId} 
            isFromPopup={config?.from === 'popup'}
            hasOneSize={hasOneSize}
            sizeAttrInfo={sizeAttrInfo}
            language={language}
            handleCalcSize={handleCalcSize}
            handleOpenSizeGuideFn={handleOpenSizeGuideFn}
            handleSizeRecommend={handleSizeRecommend}
          /> 
          : null}
      </div>
    )

    const renderSizeGroup = () => {
      if (sizeGroupPosition !== 'afterSize') return null
      const { groups } = saleAttrGroups || {}
      return groups?.map?.((group, index) => {
        if (group?.isCurrentGroup === '1') return null
        return <SizeGroupLabel
          key={index}
          sizeGroupItem={group}
          goodsId={goodsId}
          from={config?.from}
          handlerOpenAdd={handlerOpenAdd}
        />
      })
    }

    const renderLabels = () => {
      return attr_value_list.map?.((label, index) => {
        const { goods_id, size_gather_tag } = label || {}
        if (goods_id && size_gather_tag) {
          if (sizeGroupPosition) return null
          return <SizeSelectRelatedLabel 
            index={index}
            label={label}
            config={config}
            handleCalcSize={handleCalcSize}
            handlerOpenAdd={handlerOpenAdd}
          />
        }
        return <SizeSelectLabel
          attrName={attr_name}
          attrId={attr_id}
          label={label}
          index={index}
          config={config}
          sizeInfoIndex={sizeInfoIndex}
          isOnSale={isOnSale}
          sizeConstant={sizeConstant}
          handleSizeClick={handleSizeClick}
          handleCalcSize={handleCalcSize}
          handleChooseCheck={handleChooseCheck}
        />
      })
    }

    const itemContentEl = () => (
      <div>
        <ul class={[
          'SIZE_ITEM_HOOK',
          'goods-size__sizes choose-size', 
          { 'on-line__style': overflowX }]}>
          {renderLabels()}
          {renderSizeGroup()}
        </ul>
      </div>
    )

    return (
      <div>
        {itemTitleEl()}
        {itemContentEl()}
      </div>
    )
  },
}
</script>
