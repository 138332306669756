<script lang="jsx">
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import ClientOnly from 'vue-client-only'
import { Icon } from '@shein-aidc/icon-vue2'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getImageRatio } from 'public/src/pages/goods_detail/utils/common'
import SwiperSlide from 'public/src/pages/components/swiperSlide/SwiperSlide.vue'
import SwiperSlideItem from 'public/src/pages/components/swiperSlide/SwiperSlideItem.vue' 
import CarouselsImage from './CarouselsImage.vue'
import LocateLabels from './LocateLabels.vue'
import CarouselsPagination from './CarouselsPagination.vue'
import { parseQueryString, preloadImgs } from '@shein/common-function'
import { transformImg } from '@shein/common-function'
const daEventExpose = daEventCenter.getExposeInstance()
daEventCenter.addSubscriber({ modulecode: '1-6-6' })

// 埋点计数
let imgViewIndexs = new Set()

export default {
  name: 'MainPicture',
  components: {
    ClientOnly,
    SwiperSlide,
    SwiperSlideItem,
    CarouselsImage,
    LocateLabels,
    CarouselsPagination,
    MainAtmosphereFlow: () => import(/* webpackChunkName: "main_picture_atmosphere_flow" */ './MainAtmosphereFlow.vue'),
    CarouselsTopBigImage: () => import(/* webpackChunkName: "main_picture_carousels_top_big_image" */ './CarouselsTopBigImage.vue'),
    CarouselsOutfits: () => import(/* webpackChunkName: "main_picture_carousels_outfits" */ './CarouselsOutfits.vue'),
    CarouselsSizeGuide: () => import(/* webpackChunkName: "main_picture_carousels_size_guide" */ './CarouselsSizeGuide.vue'),
    CarouselsPicInterest: () => import(/* webpackChunkName: "main_picture_carousels_pic_interest" */ './CarouselsPicInterest.vue'),
    VideoPlayBtn: () => import(/* webpackChunkName: "main_picture_video_play_btn" */ './VideoPlayBtn.vue'),
    CarouselsNewGetTheLook: () => import(/* webpackChunkName: "main_picture_carousels_new_get_the_look" */ './CarouselsNewGetTheLook.vue'),
  },
  props: {
    topContentReady: { // TODO 新数据流
      type: Boolean,
      default: false
    }
  },
  data() {
    const imgRatio = this.$route.query.imgRatio
    const fixedRatio = ['1-1', '3-4', '4-5', '5-6', '13-16'].includes(imgRatio) ? imgRatio : '3-4'
    return {
      fixedRatio,                 // 补图容器比例
      isLoadBigImage: false,      // 是否加载大图    
      currentIndex: 0,            // 当前页码
      skcIndex: 0,
      isDrawPoint: false,         // 按需加载 outfits 锚点
      isClickOutfitsTab: false,   // 埋点区分
      lazyMounted: false,         // 是否懒加载
    }
  },
  computed: {
    ...mapState('newProductDetail/common', ['fromSwitchColor', 'goodsReady']),
    // BFF 数据链路
    ...mapState('productDetail/common', ['second_info_ready']),
    ...mapState('productDetail/MainPicture', ['current_image_id']),
    ...mapGetters('productDetail/MainSaleAttr', [
      'skc_prepose_val',
      'is_skc_prepose'
    ]),
    ...mapGetters('productDetail/common', ['sku_code', 'atmosphere_config', 'fs_abt']),
    ...mapGetters('productDetail/MainPicture', 
      ['locate_labels_data', 'locals', 'carouse_data', 'size_guide_data', 'video_play_info', 'outfits_info', 'first_sku_code', 'show_ar_entry']),
    // 轮播图长度
    totalIndex() {
      return this.carouse_data.carouseData?.length
    },
    // 轮播图长度
    picTotalIndex() {
      const { carouseData, CAROUSELS_TYPE } = this.carouse_data
      return carouseData.filter(item => ![CAROUSELS_TYPE.GOODS_SIZE_BEFORE_OUTFIT, CAROUSELS_TYPE.GOODS_SIZE_AFTER_OUTFIT, CAROUSELS_TYPE.GOODS_OUTFITS].includes(item.type))?.length
    },
    // 当前展示的轮播图
    currentInfo() {
      const { carouseData } = this.carouse_data || {}
      return carouseData[this.currentIndex]
    },
    // 播放按钮位置
    videoPlayLocation() {
      const { video_play_info, currentIndex } = this
      const { cccVideoPosition, isShowVideo } = video_play_info
      let cccIndex = +cccVideoPosition || 1

      if (!isShowVideo) return false

      // 居中大按钮
      if (this.totalIndex < cccIndex) cccIndex = this.totalIndex
      if (currentIndex === cccIndex - 1) {
        return 'BIG_PLAY'
      }

      // 右侧小按钮
      return 'SMALL_PLAY'
    }
  },
  watch: {
    $route(newVal, oldVal) {
      // 切颜色或者推荐跳转
      if (newVal?.params?.[1] != oldVal?.params?.[1]) {
        // 关闭大图
        this.update_is_show_big_image(false)
      }
      this.getGoodsFirstImgRatio()
      this.update_video_show_flag(false)
    },
    topContentReady: {
      handler() {
        this.swiperIndexReset()
      }
    },
    goodsReady: {
      immediate: true,
      handler(newVal, oldVal) {
        // 如果topContentReady 还没初始化，走上面topContentReady 的watch，保证 topContentReady 的组件优先上屏
        if (this.topContentReady) {
          this.swiperIndexReset()
        }
        this.update_current_image_id('')
        // 请求滑动计数
        imgViewIndexs.clear()

        if (newVal && newVal != oldVal) {
          if (typeof window === 'undefined') {
            return
          }
          this.$nextTick(() => {
            const { carouseData } = this.carouse_data
            // top组件不再二次mounted，曝光放在watch中

            // 渐进增强首图二图
            this.enHanceImg()

            // 存在spu图片时候，切换skc时需要定位到第一张skc图
            let initIndex = 0
            if (this.fromSwitchColor) {
              const _initIndex = carouseData?.findIndex(i => i?.data.is_skc_image)
              initIndex = _initIndex > -1 ? _initIndex : 0
              this.$refs?.['banner-wrap']?.slideTo(initIndex, 0, false) // 复位到第一张 | 数量不同时会进行更新
            }
            this.update_video_show_flag(true)
          })
        }
      }
    },
    second_info_ready: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.lazyMounted = true
            this.anchorToOutfits()

            const { isHasOutfits } = this.outfits_info || {}
            // outfits tab 曝光
            if (isHasOutfits) {
              daEventCenter.triggerNotice({
                daId: '1-6-1-69',
              })
            }
          })
        } else {
          this.lazyMounted = false
        }
      }
    },
    // 当前图片ID
    current_image_id(newVal) {
      if (newVal) {
        const { carouseData } = this.carouse_data
        const index = carouseData.findIndex((i) => i.data?.img_id === newVal)

        // 切换 outfits 不需要更新currentIndex
        if(index > -1 && index <= this.picTotalIndex && !this.fromSwitchColor){
          this.currentIndex = index
          this.skcIndex = index
          this.$refs?.['banner-wrap']?.slideTo(index, 500, false)
        } else if(newVal === 'size_guide') {
          const { isSizeGuideBeforeOutfit }  = this.size_guide_data || {}
          this.toggleActive({ type: isSizeGuideBeforeOutfit ? 'goodsSizeBeforeOutfit' : 'goodsSizeAfterOutfit' })
        }
      }
    },
    // 切换sku时需要锚定sku图片
    sku_code(newVal) {
      if (newVal) {
        const { carouseData = [], CAROUSELS_TYPE } = this.carouse_data
        const skuList = carouseData.filter(i => i.type == CAROUSELS_TYPE.GOODS_IMAGE && !!i.data.sku_code)
        if (skuList?.length <= 1) return //单个sku时不锚定
        const index = carouseData.findIndex((i) => i.type == CAROUSELS_TYPE.GOODS_IMAGE && i.data?.sku_code === newVal)
        if(index && index > -1){
          this.$refs?.['banner-wrap']?.slideTo(index, 500, false)
        }
      }
    },
  },
  mounted() {
    this.getGoodsFirstImgRatio()

    const { autostart = '' } = parseQueryString?.(window?.location?.search) || {}
    if (autostart == 1) {
      // 自动开启ar试穿，延迟加载，不影响首屏加载
      const timer = setTimeout(() => {
        clearTimeout(timer)
        this.openTryOnAr()
      }, 1500)
    }
  },
  beforeDestroy() {
    this.update_video_show_flag(false)
  },
  inject: {
    cutImg: {
      from: 'cutImg',
      default: (url) => url,
    },
  },
  methods: {
    ...mapActions('newProductDetail', ['openGetTheLookNewPopup']),
    ...mapMutations('newProductDetail/common', ['updateExternalSkuCode', 'updateFixedRatio']),
    ...mapMutations('newProductDetail', ['updateShowTryOnArContent']),
    // 新BFF
    ...mapMutations('productDetail/common', ['update_play_vimeo_status', 'update_fixed_ratio', 'update_hide_strange_belt']),
    ...mapMutations('productDetail/MainPicture', ['update_is_show_big_image', 'update_current_image_id']),
    ...mapMutations('productDetail/Main', ['update_video_show_flag']),
    swiperIndexReset () {
      if (!this.fromSwitchColor) {
        this.$refs?.['banner-wrap']?.slideTo(0, 0, false)
      }
    },
    // 渐进增强首图
    enHanceImg(){
      const { carouseData = [] } = this.carouse_data
      const dataSrc = carouseData[0]?.data?.origin_image
      if (!!dataSrc) {
        const preLoadImgs = []
        preLoadImgs.push(this.cutImg(dataSrc?.replace('.webp', '.jpg'), 750))

        preloadImgs({ imgs: preLoadImgs }).then(()=>{
          const imgList = this.$refs?.['banner-wrap']?.$el?.querySelectorAll('.fsp-element') || []
          imgList.forEach((item, index) => {
            const src = item.getAttribute('src') || ''
            let resultSrc = preLoadImgs[index]
            if (resultSrc) {
              resultSrc = resultSrc?.replace(/(_square)|(_squfix)/ig, '')
            }
            if (item && src !== resultSrc && resultSrc) { item.setAttribute('src', resultSrc) }
          })
        })
      }
    },
    // 获取首图宽高比例
    async getGoodsFirstImgRatio() {
      const { carouseData } = this.carouse_data
      let { imgRatio = null } = this.$route.query
      if (!imgRatio) {
        const firstImg = carouseData[0]?.data?.origin_image
        imgRatio = firstImg && await getImageRatio(firstImg)
      }
      let fixedRatio = Array.isArray(imgRatio) ? imgRatio[0] : imgRatio
      this.update_fixed_ratio(fixedRatio || '3-4')
      // TODO 下线旧状态需要删除
      this.updateFixedRatio(fixedRatio || '3-4')
      if (this.fixedRatio != fixedRatio) {
        this.fixedRatio = fixedRatio
        setTimeout(() => this.$refs['banner-wrap']?.onUpdate?.(), 500)
      }
    },

    // 轮播切换
    handleSlideChangeTransitionEnd(_, index) {
      const { carouseData, CAROUSELS_TYPE } = this.carouse_data
      this.currentIndex = index
      if(this.skc_prepose_val && this.skc_prepose_val === 'C' && index < this.picTotalIndex ){
        this.skcIndex = index
      }
      if (this.currentInfo?.type === CAROUSELS_TYPE.GOODS_IMAGE && this.currentInfo.data?.img_id) {
        this.update_current_image_id(this.currentInfo.data?.img_id)
      }

      // 进入outfits埋点
      if (this.currentInfo?.type === CAROUSELS_TYPE.GOODS_OUTFITS) {
        daEventCenter.triggerNotice({
          daId: '1-6-1-70',
          extraData: {
            entry_mode: this.isClickOutfitsTab ? 2 : 1,
          },
        })
        if (this.isClickOutfitsTab) {
          // 曝光锚点
          const { outfitsInfo, outfitsAbTest } = this.outfits_info || {}
          outfitsInfo?.labels.forEach((anchor, index) => {
            if (!anchor.expose) {
              anchor.expose = true
              const { label, rec_mark, label_id } = anchor
              daEventCenter.triggerNotice({
                daId: '1-6-1-71',
                extraData: {
                  abtest: outfitsAbTest,
                  tab_list: `${index + 1}\`${label_id}\`\`${label}`,
                  rec_mark,
                },
              })
            }
          })
        }
        this.isClickOutfitsTab = false
        // 点击 outfits 再加载锚点
        this.isDrawPoint = true
      } else {
        // 剔除 outfits
        imgViewIndexs.add(index + 1)
      }

      // 利益点重复曝光
      if (this.currentInfo?.type === CAROUSELS_TYPE.GOODS_INTEREST) {
        daEventExpose.reset('expose-interest-events')
      }
      // sizeguide曝光
      if ([CAROUSELS_TYPE.GOODS_SIZE_BEFORE_OUTFIT, CAROUSELS_TYPE.GOODS_SIZE_AFTER_OUTFIT].includes(this.currentInfo?.type)) {
        daEventCenter.triggerNotice({
          daId: '1-6-1-252',
        })
      }

      if(typeof window !== 'undefined' && window?.SaPageInfo?.page_param) {
        const imgViewArray = [...imgViewIndexs]
        const imgViewMaxIndex = Math.max(...imgViewArray)
        
        let img_view_index = imgViewMaxIndex || 1
        let gds_img_view_cnt = imgViewArray?.length || 1
        const is_show_sizetab = this.size_guide_data?.sizeGuideInfo?.imgUrl ? 1 : 0
        
        // 存在利益点
        const interestIndex = carouseData?.findIndex(i => i?.type === CAROUSELS_TYPE.GOODS_INTEREST)
        if (interestIndex > 0 && imgViewIndexs.has(interestIndex + 1)) {
          // 利益点在outfits后面时
          if (img_view_index >= interestIndex + 1) {
            img_view_index = img_view_index - 1
          }
          gds_img_view_cnt = gds_img_view_cnt - 1
        }

        Object.assign(window.SaPageInfo.page_param, {
          img_view_index,
          gds_img_view_cnt,
          is_show_sizetab
        })
      }
    },
    // 切换页码
    toggleActive({ type, duration = 500 }){
      let index = 0
      if(this.skc_prepose_val && this.skc_prepose_val === 'C'){
        index = this.skcIndex
      }
      if (type === 'outfits') {
        // 不能直接定位到最后面，利益点有可能是最后一个
        const { carouseData, CAROUSELS_TYPE } = this.carouse_data
        index = carouseData.findIndex(i => i.type === CAROUSELS_TYPE.GOODS_OUTFITS)
        this.isClickOutfitsTab = true
      }else if(type === 'goodsSizeBeforeOutfit'){
        const { carouseData, CAROUSELS_TYPE } = this.carouse_data
        index = carouseData.findIndex(i => i.type === CAROUSELS_TYPE.GOODS_SIZE_BEFORE_OUTFIT)
      } else if (type === 'goodsSizeAfterOutfit') {
        const { carouseData, CAROUSELS_TYPE } = this.carouse_data
        index = carouseData.findIndex(i => i.type === CAROUSELS_TYPE.GOODS_SIZE_AFTER_OUTFIT)
      }
      this.$refs['banner-wrap'].slideTo(index, duration)
    },
    // 点击主图
    handleGoodsImgClick({ type = '' } = {}) {
      if(type === 'size_guide') {
        this.update_current_image_id('size_guide')
      }
      if (!this.isLoadBigImage) {
        this.isLoadBigImage = true
      } else {
        this.update_is_show_big_image(true)
      }
    },
    // 打开新搭配弹窗
    handleOpenOutfitPop(type, cv_goods_id) {
      this.openGetTheLookNewPopup({ initCateTab: type !== 'newGtlOutfit' ? cv_goods_id : '', activeFrom: 'outfittab' })
    },
    // 定位到 outfits
    anchorToOutfits() {
      const { isHasOutfits } = this.outfits_info || {}
      const { outfits = 0 } = parseQueryString?.(window?.location?.search) || {}
      const hasOutfitsQuery = +outfits === 1
      if (hasOutfitsQuery && isHasOutfits) {
        setTimeout(() => this.toggleActive({ type: 'outfits' }), 500)
      }
    },
    // 开启试穿ar
    openTryOnAr() {
      if (!this.sku_code) {
        // 没选中默认选中第一个sku
        this.updateExternalSkuCode(this.first_sku_code || '')
        // 延迟等待skuCode更新
        const timer = setTimeout(() => {
          clearTimeout(timer)
          this.updateShowTryOnArContent(true)
        })
        return
      }
      this.updateShowTryOnArContent(true)
    },
  },
  render() {
    const { GB_cssRight, language } = this.locals
    const { carouseData, CAROUSELS_TYPE } = this.carouse_data || {}

    { /* 轮播主图 */ }
    const renderMainPicture = () => {
      return (
        <SwiperSlide
          ref="banner-wrap"
          aria-hidden="true"
          touchable={this.goodsReady}
          class="fsp-element"
          observer-length
          direction={GB_cssRight ? 'rtl' : 'ltr'}
          item-length={this.totalIndex}
          vOn:activeIndexChange={this.handleSlideChangeTransitionEnd}
        >
          {carouseData.map((item, index) => {

            { /* 商品图片 */ }
            if (item.type === CAROUSELS_TYPE.GOODS_IMAGE) {
              return (
                <CarouselsImage 
                  index={index}
                  item={item.data}
                  locals={{
                    ...this.locals,
                    fixedRatio: this.fixedRatio,
                  }}
                  vOn:clickGoodsImg={this.handleGoodsImgClick}
                >

                  {/* 主图Beauty品类蒙版 */}
                  {this.fixedRatio === item.data?.maskLayer?.imgRatio && (
                    <template slot="fistMask">
                      <img 
                        class="main-picture__mask-layer"
                        src={item.data?.maskLayer?.src}
                      />
                    </template>
                  )}

                  {/* 主图角标 */}
                  { this.locate_labels_data?.length && (
                    <template slot="locateLabels">
                      <LocateLabels 
                        locateLabelData={this.locate_labels_data}
                      />
                    </template>
                  )}
                </CarouselsImage>
              )
            }

            { /* 权益保障利益点 */ }
            if (item.type === CAROUSELS_TYPE.GOODS_INTEREST) {
              return (
                <CarouselsPicInterest
                  is-single={this.isSingleMainPic}
                  language={language}
                  from='productItem'
                  index={index}
                  picInterestInfo={item.data}
                  vOn:handleSwiperSlideLoaded={() => {
                    this.$refs['banner-wrap']?.onUpdate?.()
                  }}
                />
              )
            }
            { /* 搭配套装 outfits */ }
            if (item.type === CAROUSELS_TYPE.GOODS_SIZE_BEFORE_OUTFIT) {
              return (
                <CarouselsSizeGuide 
                  index={index}
                  item={item.data}
                  is-draw-point={this.isDrawPoint}
                  vOn:handleSwiperSlideLoaded={() => {
                    this.$refs['banner-wrap']?.onUpdate?.()
                  }}
                  vOn:handleGoodsImgClick={() => {
                    this.handleGoodsImgClick({ type: 'size_guide' })
                  }}
                />
              )
            }
            { /* 搭配套装 outfits */ }
            if (item.type === CAROUSELS_TYPE.GOODS_OUTFITS) {
              return (
                <CarouselsOutfits 
                  index={index}
                  item={item.data}
                  locals={{
                    ...this.locals,
                    fixedRatio: this.fixedRatio,
                  }}
                  is-draw-point={this.isDrawPoint}
                  vOn:handleSwiperSlideLoaded={() => {
                    this.$refs['banner-wrap']?.onUpdate?.()
                  }}
                  vOn:handleOpenOutfitPop={this.handleOpenOutfitPop}
                />
              )
            }
            { /* 搭配套装 outfits */ }
            if (item.type === CAROUSELS_TYPE.GOODS_SIZE_AFTER_OUTFIT) {
              return (
                <CarouselsSizeGuide 
                  index={index}
                  item={item.data}
                  is-draw-point={this.isDrawPoint}
                  vOn:handleSwiperSlideLoaded={() => {
                    this.$refs['banner-wrap']?.onUpdate?.()
                  }}
                  vOn:handleGoodsImgClick={() => {
                    this.handleGoodsImgClick({ type: 'size_guide' })
                  }}
                />
              )
            }
          })}
        </SwiperSlide>
      )
    }

    { /* 相对于屏幕的绝对布局元素 */ }
    const renderFixedLayout = () => {
      const { isHasOutfits, gtlInfo } = this.outfits_info || {}
      const { isSizeGuideBeforeOutfit, isSizeGuideAfterOutfit }  = this.size_guide_data || {}
      // 避免切换商品时上一个商品状态没有及时更新
      const newoutfitAbt = this.fs_abt.newoutfit?.p?.newoutfit
      const isShowOutfits = newoutfitAbt === 'new' && isHasOutfits && this.second_info_ready
      const isShowSizeGuideBeforeOutfit = isSizeGuideBeforeOutfit && this.second_info_ready
      const isShowSizeGuideAfterOutfit = isSizeGuideAfterOutfit && this.second_info_ready
      // 是否选中 outfits  -- 利益点不同位置逻辑不一样
      const isActiveOutfits = isHasOutfits && (this.currentInfo?.type === CAROUSELS_TYPE.GOODS_OUTFITS)
      const isActiveSizeGuideBeforeOutfit = isSizeGuideBeforeOutfit && (this.currentInfo?.type === CAROUSELS_TYPE.GOODS_SIZE_BEFORE_OUTFIT)
      const isActiveSizeGuideAfterOutfit = isSizeGuideAfterOutfit && (this.currentInfo?.type === CAROUSELS_TYPE.GOODS_SIZE_AFTER_OUTFIT)
      // 是否展示 gtl
      const isShowGtl = newoutfitAbt === 'new' && isActiveOutfits && !!gtlInfo && (this.currentInfo?.type === CAROUSELS_TYPE.GOODS_OUTFITS )
      // 隐藏异常腰带
      this.update_hide_strange_belt(isShowGtl)

      return (
        <ClientOnly>
          { /* 人气氛围 */ }
          { this.lazyMounted && (!isActiveOutfits && !this.show_ar_entry && !isActiveSizeGuideBeforeOutfit && !isActiveSizeGuideAfterOutfit) 
            && (
              <MainAtmosphereFlow 
                location="main_image"
                class="main-picture__atmosphere-flow"
              />
            )}

          {/* 视频播放按钮 */}
          { this.video_play_info.isShowVideo && (
            <VideoPlayBtn
              location={this.videoPlayLocation}
              config={this.video_play_info}
              current-index={this.currentIndex}
              video-position={+this.video_play_info?.cccVideoPosition || 1}
              vOn:playVimeo={() => this.update_play_vimeo_status(true)}
            />
          )}

          {/* 轮播页吗 */}
          { this.goodsReady && (
            <div class="main-picture__bottom">
              <CarouselsPagination
                locals={this.locals}
                pic-total-index={this.picTotalIndex}
                current-index={this.currentIndex}
                skc-index = {this.skcIndex}
                is-show-outfits={isShowOutfits}
                is-show-size-guide-before-outfit={isShowSizeGuideBeforeOutfit}
                is-show-size-guide-after-outfit={isShowSizeGuideAfterOutfit}
                is-active-outfits={isActiveOutfits}
                is-active-size-guide-before-outfit={isActiveSizeGuideBeforeOutfit}
                is-active-size-guide-after-outfit={isActiveSizeGuideAfterOutfit}
                key={`${this.$route.path}`}
                vOn:toggleActive={this.toggleActive}
              />

              {/* gtl */}
              { !!gtlInfo && <CarouselsNewGetTheLook 
                show={isShowGtl}
                gtl-info={gtlInfo}
                vOn:handleOpenOutfitPop={this.handleOpenOutfitPop}
              />}
              { isShowGtl && <div class="main-picture__bottom-mask"></div>}
            </div>
          )}

          {/* AR 试装 */}
          { (this.show_ar_entry && !isActiveOutfits) && (
            <div
              {...{ directives: [{ name: 'expose', value: { id: '1-6-1-163' } }, { name: 'tap', value: { id: '1-6-1-164' } }] }}
              class="main-picture__try—ar"
              vOn:click_stop={this.openTryOnAr}>
              <Icon name="sui_icon_ar_fat_xs" size="16px" />
                TRY IN AR
            </div>
          )}
        </ClientOnly>
      )
    }

    return (
      <div
        {...{ directives: [{ name: 'ada', value: { level: 1, pos: [0] } }] }}
        id="banner-detail"
        class="main-picture"
        role="text"
        aria-label={language?.SHEIN_KEY_PWA_15408}
      >
        {/* 轮播主图 */}
        { renderMainPicture() }

        {/* 相对于屏幕的绝对布局元素 */}
        { renderFixedLayout() }

        {/* 轮播大图 */}
        { this.isLoadBigImage && <CarouselsTopBigImage />}
      </div>
    )
  }
}

</script>

<style lang="less" scoped>
.main-picture {
  position: relative;
  margin: 0 -.32rem;

  &__slide-item {
    position: relative;
    width: 10rem;
  }

  &__atmosphere-flow {
    bottom: 8%;
  }

  &__bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: @zindex-out;
  }

  &__bottom-mask {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: calc(100% + 1.2rem);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 100%);
    z-index: -1;
    pointer-events: none;
  }

  &__mask-layer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &__try—ar {
    position: absolute;
    top: 0.17rem;
    left: 0.17rem;
    display: inline-flex;
    padding: 0.16892rem 0.27027rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.40541rem;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);

    font-size: 0.23649rem;
    font-style: normal;
    font-weight: 700;
    & > span {
      margin-right: 0.14rem;
    }
  }
}
</style>
