<template>
  <div
    ref="sizeRecommendRef" 
    class="recommendEntrance-size"
    :class="{
      'fold-attr-recommendEntrance-size': showSaleAttrFold,
      'recommendEntrance-size-self': sizeRecommendType != 2 && (showCheckMySizeEntrance || showSizeGuideEntry)
    }"
  >
    <!-- 第三方size推荐 -->
    <div 
      v-if="showCheckMySizeExternal"
      class="j-sa-check-size j-btn-check-my-size-wrapper"
    ></div>
    <a
      v-if="showCheckMySizeEntrance"
      class="link-check-my-size"
      :class="{ 'link-check-my-size-planA': true }"
      @click="selfSizeRecommendClick"
    >
      <Icon 
        v-if="showSaleAttrFold"
        name="sui_icon_check_my_size_14px"
        color="#000"
      />
      <p v-html="recommendSizeTextDom"></p>
      <span class="icon suiiconfont sui_icon_more_right_12px_2"></span>
    </a>
    <!-- Sizeguide新入口 -->
    <a
      v-if="showSizeGuideEntry"
      v-expose="{ id: '1-6-1-137', data: { from: isFromPopup ? 2 : 1 } }"
      v-tap="{ id: '1-6-1-138', data: { from: isFromPopup ? 2 : 1 } }"
      state="sizeGuideSizeEntry"
      class="link-check-my-size j-push-history-hash"
      :class="{ 
        'link-check-my-size-planA': true,
        'has-left-gap': (showSizeGuideEntry && showCheckMySizeEntrance) || showCheckMySizeExternal,
      }"
      @click="handleSizeGuide"
    >
      <Icon 
        v-if="showSaleAttrFold"
        name="sui_icon_sizeguide_14px"
        color="#000"
      />
      <p>
        {{ language.SHEIN_KEY_PWA_15005 }}
      </p>
      <span class="icon suiiconfont sui_icon_more_right_12px_2"></span>
    </a>
  </div>
</template>

<script setup>
import { defineProps, watch, computed, ref, onMounted, onBeforeUnmount } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
const props = defineProps({
  sizeRecommendInfo: {
    type: Object,
    default: () => {},
  },
  recommendSizeTextDom: {
    type: String,
    default: '',
  },
  sizeAttrInfo: {
    type: Object,
    default: () => ({}),
  },
  language: {
    type: Object,
    default: () => {},
  },
  goodsId: {
    type: String,
    default: '',
  },
  hasOneSize: {
    type: Boolean,
    default: false,
  },
  isFromPopup: {
    type: Boolean,
    default: false,
  },
  handleCalcSize: {
    type: Function,
    default: () => {},
  },
  handleOpenSizeGuideFn: {
    type: Function,
    default: () => {},
  },
  handleSizeRecommend: {
    type: Function,
    default: () => {},
  },
  showSaleAttrFold: {
    type: Boolean,
    default: false,
  }
})
const sizeRecommendRef = ref(null)
const loadPdpError = ref(false)
const timer = ref('')
const domObserver = ref(null)
const sizeRecommendType = computed(() => {
  if (loadPdpError.value || props.hasOneSize) return 0
  return props.sizeRecommendInfo?.sizeRecommendType || 0
})
const sizeGuidAlone = computed(() => props.sizeRecommendInfo?.sizeGuidAlone || false)
const showSizeGuide = computed(() => props.sizeRecommendInfo?.showSizeGuide || false)

/* 
  todo: 后期收拢
    1. sizeRecommendType：1-自有checkMySize 2-第三方checkMySize 0-sizeguide
    2. 命中尺码折叠场景（showSaleAttrFold=true），自有checkMySize 和 sizeguide 一定是分开显示
    3. 正常场景（尺码非折叠场景），sizeGuidAlone=true（checkMySize & sizeguide分开两行展示）
    4. 正常场景（尺码非折叠场景），sizeGuidAlone=false（checkMySize & sizeguide合并展示），优先展示自有checkMySize，其次展示第三方checkMySize（第三方checkMySize加载失败则展示sizeguide），最后展示sizeguide
*/
// sizeguide入口
const showSizeGuideEntry = computed(() => showSizeGuide.value && (props.showSaleAttrFold || sizeGuidAlone.value || (!sizeGuidAlone.value && sizeRecommendType.value === 0)))
// 自有check my size
const showCheckMySizeEntrance = computed(() => sizeRecommendType.value === 1 && (props.showSaleAttrFold || !sizeGuidAlone.value))
// 第三方 check my size 
const showCheckMySizeExternal = computed(() => sizeRecommendType.value === 2 && (props.showSaleAttrFold || !sizeGuidAlone.value))

const exposeSizeRecommend = (method) => {
  if(!method) return
  daEventCenter.triggerNotice({
    daId: '1-6-1-43',
    extraData: {
      goods_id: props.goodsId || '',
      method,
    }
  })
}
const reportSizeBi = () => {
  const method = sizeRecommendType.value == 1 ? 'self' : 'third_party'
  daEventCenter.triggerNotice({
    daId: '1-6-1-44',
    extraData: {
      goods_id: props.goodsId || '',
      method,
    }
  })
}
const traceCheckSize = () => {
  const touchEleClass = '.fitanalytics__button'
  const touchstartHandler = () => timer = Date.now()
  const touchendHandler = () => {
    if (Date.now() - timer <= 300) {
      reportSizeBi()
    }
    timer = -1
  }
  // 无法监听到第三方的点击事件
  let timer = -1
  $(document).on('touchstart', touchEleClass, touchstartHandler)
  $(document).on('touchend', touchEleClass, touchendHandler)
  onBeforeUnmount(() => {
    clearTimeout(timer.value)
    $(document).off('touchstart', touchEleClass, touchstartHandler)
    $(document).off('touchend', touchEleClass, touchendHandler)
    domObserver.value?.disconnect?.()
  })
}
const handleSizeGuide = () => {
  props.handleOpenSizeGuideFn?.('sizeGuideSizeEntry', props.isFromPopup ? 'cart_title' : 'detail_title')
}
const selfSizeRecommendClick = () => {
  props.handleSizeRecommend?.()
  reportSizeBi()
}

watch(sizeRecommendType, async (val) => {
  if (typeof window === 'undefined') return
  if (val === 1 && !sizeGuidAlone.value) {
    exposeSizeRecommend('self')
  }
  if (val === 2 && !sizeGuidAlone.value) {
    try {
      const { message = '' } = await window.TPM?.runImmediate({
        marketing: 'Fitanalytics',
        method: '_loadPdp',
      })
      if (message == 'success') {
        clearTimeout(timer.value)
        timer.value = setTimeout(() => {
          const hasThirdDom = () => {
            if (typeof window === 'undefined') return false
            const fitanalyticsButton = document?.getElementsByClassName('fitanalytics__button')?.[0]
            return fitanalyticsButton && fitanalyticsButton?.style?.display !== 'none'
          }
          if (hasThirdDom()) {
            exposeSizeRecommend('third_party')
          }else if(showSizeGuide.value) {
            const config = { attributes: true, childList: true, subtree: true }
            domObserver.value?.disconnect?.()
            domObserver.value = new MutationObserver(() => {
              if(hasThirdDom()) {
                const itanalyticsBtnDom = document.getElementsByClassName('fitanalytics__button') ?? []
                itanalyticsBtnDom?.length && (itanalyticsBtnDom[0].style.display = 'none')
                // observer.disconnect()
              }
            })
            const targetElement = sizeRecommendRef.value
            targetElement?.nodeType && domObserver.value?.observe?.(targetElement, config)
            loadPdpError.value = true
          }
        }, 3000)
      }else {
        loadPdpError.value = true
      }
    }catch(e) {
      loadPdpError.value = true
    }   
  }
}, { immediate: true })

onMounted(() => {
  traceCheckSize()
})
</script>

<style lang="less">
.recommendEntrance-size{
  display: flex;
  flex:1;
  justify-content: flex-end;
  overflow: hidden;
}
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.link-check-my-size {
  display: inline-flex;
  align-items: center;
  cursor: auto;
  width: auto;
  margin-left: .13rem;
  margin-bottom: 0.1.7rem;
  text-decoration-line: none;
  line-height: normal;
  overflow: hidden;
  p {
    max-width: 3.22667rem;
    .text-overflow();
    font-size: 12px;
    color: @sui_color_gray_dark3;
  }
  .icon {
    margin-left: 8/75rem;
  }
  &-planA{
    padding: .05333333rem .16rem;
    border-radius: .53333333rem;
    background-color: #f6f6f6;
    p{
      color: var(---sui_color_gray_dark1, #000);
    }
    .sui_icon_more_right_12px_2{
      color: var(---sui_color_gray_dark1, #000);
    }
    .icon {
    margin-left: .053333333rem;
  }
  }
}
.link-check-mysize__img {
  width: 0.454rem;
  height: 0.454rem;
  margin-right: 0.04rem;
  vertical-align: bottom;
  cursor: pointer;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
}

/* 修复 ios 第三方尺码层级不生效 */
html.uclw_widget_open {
    #uclw_wrapper {
        transform: translate3d(0, 0, 999px);
    }
}

.fold-attr-recommendEntrance-size {
  justify-content: flex-start !important;
  .link-check-my-size {
    margin-left: 0;
    &-planA {
      background-color: unset;
      padding: .05333333rem 0;
    }
    >p {
      margin-left: 4px;
    }
    &.has-left-gap {
      margin-left: 16px;
    }
  }

  .j-sa-check-size {
    margin-top: 8px;
  }

  .fitanalytics__button {
    order: initial !important;
    margin-left: 0 !important;
  }
}
.recommendEntrance-size-self {
  margin-top: 8px;
}
</style>
