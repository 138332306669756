var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'goods-size-group__wrapper',
    {
      'goods-size-group__relation-wrapper': _setup.isFoldScene
    }
  ]},[_c('div',{staticClass:"goods-size-group__title",attrs:{"aria-hidden":"true"},on:{"click":_setup.controlSlideExpandStatus}},[_c('div',{class:[
        'goods-size__title-txt',
        {
          'goods-size__title-bold': _vm.isFromDetailPage,
          'goods-size-group__title-txt': _setup.isFoldScene,
        }
      ]},[_vm._v("\n      "+_vm._s(_setup.title)+"\n    ")]),_vm._v(" "),(_setup.isFoldScene)?_c('span',{directives:[{name:"expose",rawName:"v-expose",value:({
        id: '1-6-1-196',
        data: {
          location: _setup.location,
          goods_id: _vm.goodsId,
        },
      }),expression:"{\n        id: '1-6-1-196',\n        data: {\n          location,\n          goods_id: goodsId,\n        },\n      }"}],staticClass:"more-icon"},[_c(_setup.Icon,{directives:[{name:"show",rawName:"v-show",value:(!_vm.globalPerfectFitShow),expression:"!globalPerfectFitShow"}],attrs:{"name":"sui_icon_more_down_12px","color":"#222","size":"14px"}}),_vm._v(" "),_c(_setup.Icon,{directives:[{name:"show",rawName:"v-show",value:(_vm.globalPerfectFitShow),expression:"globalPerfectFitShow"}],attrs:{"name":"sui_icon_more_up_12px","color":"#222","size":"14px"}})],1):_vm._e()]),_vm._v(" "),_c(_setup.isFoldScene ? _setup.SSlide : 'div',{tag:"components",attrs:{"visible":_vm.globalPerfectFitShow}},[_c('div',{directives:[{name:"expose",rawName:"v-expose",value:(_setup.getAnalysisData({ type: 'expose' })),expression:"getAnalysisData({ type: 'expose' })"}],class:{
        'goods-size-group__content': true,
        'perfect-fit': _setup.isFoldScene,
      }},[_c('ul',{staticClass:"goods-size__sizes choose-size"},_vm._l((_setup.groups),function(item){return _c('li',{directives:[{name:"tap",rawName:"v-tap",value:(_setup.getAnalysisData({ type: 'click', item })),expression:"getAnalysisData({ type: 'click', item })"}],key:item.goodsId,class:{
            'goods-size__sizes-item': true,
            'size-active': item.isCurrentGroup == '1',
          },attrs:{"type":"text"},on:{"click":function($event){return _setup.clickSizeGroupItem(item)}}},[_c('span',{staticClass:"goods-size__sizes-item-text"},[_vm._v("\n            "+_vm._s(item.displayDesc)+"\n          ")]),_vm._v(" "),(_setup.isAfterSize || _setup.isFoldScene)?_c('i',{staticClass:"suiiconfont sui_icon_more_right_12px_2"}):_vm._e()])}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }