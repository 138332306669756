<script lang="jsx">
export default {
  name: 'SizeStockTip',
  functional: true,
  props: {
    showNewBubbleStyle: {
      type: Boolean,
      default: false,
    },
    
    soldOutTips: {
      type: String,
      default: '',
    },
    
    analysisData: {
      type: Object,
      default: () => ({}),
    }
  },
  render(h, { props = {} }) {
    const { 
      soldOutTips,
      showNewBubbleStyle = false,
      analysisData
    } = props

    const stockTips = () => {
      if (!soldOutTips) return null

      const tipDom = <span
        class="tips"
        v-expose={{
          id: '1-6-1-235',
          data: analysisData,
        }}>
        {soldOutTips}
      </span>
      if (showNewBubbleStyle) {
        return <div class="goods-size__item-bubble-info-header">
          <Icon
            name="sui_icon_hourglass_12px_2"
            class="hourglass-icon"
            size="12px"
            color="#FA6338"
          />{tipDom}
        </div> 
      }
      return tipDom
    }

    return stockTips()
  },
}
</script>
