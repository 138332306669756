export const COMMON_STATE_KEYS = [
  'externalSizeInfoIndex', 
  'externalSkuCode',
  'skuInfo',
  'currentMySize',
  'currentMyBraSize',
]

export const FOLD_GETTER_KEYS = [
  'saleAttrsArr', 
  'colorsFoldInfo',
  'onlyOneSize',
  'sizeComposeInfo',
  'language',
  'sizeStockTips',
  'soldOutTips',
  'sizeConfig',
  'sizeConstant',
  'currentUnit',
  'goodsMainAttPicInfo',
  'goodsDesc',
  'tipsCombo',
  'sizeFeedBackBind',
  'localSelectComb',
  'quickShipBind',
  'mallBind',
  'compShowConfig',
  'hideOneSizeModule',
]

export const COMMON_GETTER_KEYS = [
  'detail',
  'mallCode',
  'goodsId',
  'parentCats',
  'allDataReady',
  'currentMall'
]

export const COMMON_MUTATION_KEYS = [
  'updateSyncAddCartColorSwitch',
  'updateQuickAddState',
  'updateCurrentLocalCountry',
  'updateQuickShip',
]
