<script lang="jsx">
import { daEventCenter } from 'public/src/services/eventCenter/index'
// import { PromotionUtils } from 'public/src/pages/common/promotion/utils'
import EstimatedTag from 'public/src/pages/goods_detail/components/top/innerComponents/Prices/components/EstimatedTag/index.vue'
import ExclusiveTag from 'public/src/pages/goods_detail/components/top/innerComponents/Prices/components/MemberTag/components/ExclusiveTag.vue'
import SuggestedSalePrice from 'public/src/pages/goods_detail/components/top/innerComponents/Prices/components/PriceLeft/SuggestedSalePrice.vue'
import ClientOnly from 'vue-client-only'
import { parsePercentText } from 'public/src/pages/goods_detail/utils/ar.js'
import { sui_icon_time_15px } from '@shein-aidc/icon-vue2'

// import VipPaidTag from './MemberTag/components/VipPaidTag'
// import VipUnpaidTag from './MemberTag/components/VipUnpaidTag'
// import { markPoint } from 'public/src/services/mark/index.js'

daEventCenter.addSubscriber({ modulecode: '1-6-1' })

const exclusiveTagShowModalAnalysis = (isShow) => {
  if (isShow) {
    daEventCenter.triggerNotice({
      daId: '1-6-1-29',
      extraData: {
        from: 1,
      },
    })
  }
}

// const handleClick = ({ analysisData, isSheinClubUser, paidVipData }) => {
//   daEventCenter.triggerNotice({
//     daId: '1-6-1-65',
//     extraData: analysisData,
//   })
//   const { langPath } = gbCommonInfo
//   markPoint('toNextPageClick', 'public')
//   if (isSheinClubUser) {
//     PromotionUtils.jumpToAdd(paidVipData, 'goodsDetail')
//   } else {
//     _gb_app_ && _gb_app_.$router.push(`${langPath}/user/prime?productsource=goodsDetail`)
//   }
// }


export default {
  name: 'PriceLeft',
  functional: true,
  props: {
    sheinClubInfo: {
      type: Object,
      default: () => ({}),
    },
    priceCommon: {
      type: Object,
      default: () => ({}),
    },
    priceFlashInfo: {
      type: Object,
      default: () => ({}),
    },
    showFrom: {
      type: Boolean,
      default: false,
    },
    estimatedInfo: {
      type: Object,
      default: () => ({}),
    },
    discountInfo: {
      type: Object,
      default: () => ({}),
    },
    iconListData: {
      type: Object,
      default: () => ({}),
    },
    salePrice: {
      type: Object,
      default: () => ({}),
    },
    exclusiveInfo: {
      type: Object,
      default: () => ({}),
    },
    priceAbtConfig: {
      type: Object,
      default: () => ({}),
    },
    vipInfo: {
      type: Object,
      default: () => ({}),
    },
    goodsId: {
      type: String,
      default: '',
    },
    showVipExplaination: {
      type: Boolean,
      default: false,
    },
    showInclusiveTips: {
      type: Boolean,
      default: false,
    },
    getEstimatedInfo: {
      type: Object,
      default: () => ({}),
    },
    promotionInfo: {
      type: Array,
      default: () => ([]),
    },
    isBeltType: {
      type: Boolean,
      default: false
    },
    isBeltTypeBrand: {
      type: Boolean,
      default: false
    },
    taxIncludedConfig: {
      type: Object,
      default: () => ({})
    },
    isFloorType: {
      type: Boolean,
      default: false
    },
    complianceModeDe: {
      type: Boolean,
      default: false
    },
    realtimeReady: {
      type: Boolean,
      default: false
    }
  },
  render(h, { listeners, props }) {
    let {
      // sheinClubInfo,
      priceCommon,
      priceFlashInfo,
      showFrom,
      estimatedInfo,
      iconListData,
      discountInfo,
      salePrice,
      showVipExplaination,
      showInclusiveTips,
      priceAbtConfig,
      exclusiveInfo,
      // vipInfo,
      goodsId,
      getEstimatedInfo,
      promotionInfo,
      isBeltType,
      isFloorType,
      isBeltTypeBrand,
      taxIncludedConfig,
      complianceModeDe,
      realtimeReady
    } = props || {}
    let { language, lang_rp, IS_RW, IMG_LINK, PUBLIC_CDN_rp, IS_JP_LANGUAGE } = priceCommon || {}
    let { isSpecialFlash, flashType, isFlash, isBrandFlash } = priceFlashInfo || {}
    let specialFlashText = flashType === 2 ? language.SHEIN_KEY_PWA_21931 : language.SHEIN_KEY_PWA_21932
    let { hasEstimated } = estimatedInfo || {}
    let showOnlineVipTags = (exclusiveInfo.exclusivePromotionPrice)
    let hasEstimatedTag = hasEstimated
    if (['New1', 'New2'].includes(getEstimatedInfo?.EstimatedNothreShowType) && !getEstimatedInfo.isSatisfied && !getEstimatedInfo?.isSatisfiedBuyMultiple) {
      hasEstimatedTag = false
    }
    let styleMap = {}
    if (isBeltType) {
      if (!isBeltTypeBrand){
        styleMap = {
          from: isSpecialFlash ? null : { color: '#000' },
          mainPrice: isSpecialFlash ? null : { color: '#000' },
          discountContainer: salePrice.isSheinappwordsizeNew ? {
            transform: 'translateY(-0.08rem)'
          } : {},
          // discountPrice: {
          //   'align-self': 'flex-end'
          // },
          discountPercent: isSpecialFlash ? null : {
            background: '#FA6338',
            color: '#FFF',
          }
        }
      } else {
        styleMap = {
          from: { color: '#FFF' },
          mainPrice: { color: '#FFF' },
          discountContainer: salePrice.isSheinappwordsizeNew ? {
            transform: 'translateY(-0.0267rem)'
          } : {},
          discountPrice: {
            color: 'rgba(255, 255, 255, 0.8)'
          },
          discountPercent: {
            background: '#FFF',
            color: '#FF3D00',
            'font-weight': 700,
            'border-radius': '2px'
          }
        }
      }
    }
    if (isFloorType) {
      let discountPercent = {}
      if (isFlash) {
        discountPercent = {
          background: '#FA6338',
          color: '#FFF',
          'font-weight': 400,
          'border-radius': '2px'
        }
      }
      if (isBrandFlash) {
        discountPercent = {
          background: '#FA6338',
          color: '#FFF',
          'font-weight': '400',
          'border-radius': '2px'
        }
      }
      styleMap = {
        from: { color: isFlash && !isSpecialFlash ? '#000' : '' },
        mainPrice: { color: isFlash && !isSpecialFlash ? '#000' : '' },
        discountContainer: salePrice.isSheinappwordsizeNew ? {
          transform: 'translateY(-0.08rem)'
        } : {},
        discountPrice: {
          color: 'rgba(0, 0, 0, 0.5)'
        },
        discountPercent
      }
    }
    let bigSmallPriceStyles = {}
    if (salePrice.isSheinappwordsizeNew) {
      bigSmallPriceStyles = {
        container: {
          'align-items': (hasEstimatedTag || discountInfo.suggestedSalePrice.show) ? 'center' : 'flex-end',
        },
        discountContainer: {
          'align-items': 'flex-end',
        },
        discountPrice: {
          'transform': 'translateY(-0.04rem)'
        }
      }
    }
    let { deDiscountInfo } = discountInfo

    const specialFlashExposeData = {
      id: flashType == 2 ? '1-6-1-67' : '1-6-1-68',
      data: {
        exclusvieshowtype: 2
      }
    }

    /**
     * 价格右侧提示组件
     */
    const { show, type, content, placemen, subContent } = taxIncludedConfig
    let showPopoverEl = (show && type == 'popover')

    const fromLabel = (isShow, marginLeft) => {
      return isShow && showFrom ? <div
        id="goods-price__from"
        class={[
          'goods-price__from',
          { 'goods-price__margin-left': marginLeft },
          ...(salePrice.classNames || [])
        ]}
        style={styleMap.from}
      >{language.SHEIN_KEY_PWA_15320}</div> : null 
    }
    
    return (
      <div  data-comp="pricesleft"  class={['goods-price__left', { 'goods-price__left-special-flash': isSpecialFlash && !isBeltType && !isFloorType }]}>
        { isSpecialFlash && !isBeltType && !isFloorType ?
          <div
            class="goods-price__special-flash-content"
            {...{ directives: [{ name: 'expose', value: specialFlashExposeData }] }}
          >{specialFlashText}</div> : null
        }
        <div
          class={['goods-price__basic', { 'goods-price__basic-special-flash': hasEstimatedTag }]}
          style={bigSmallPriceStyles.container}
        >
          <div class={['goods-price__prices', 'goods-price__prices-dom', { 'goods-price__prices-has-estimated': hasEstimatedTag && !discountInfo.suggestedSalePrice.show }]}>
            {iconListData.showMoreDiscount ? (
              <div class="j-promotion-icon-ctn">

                {iconListData.iconList?.map((item, index) => {
                  return <sui_icon_time_15px key={index} color="#fa6338" size="18"/>
                })}
              </div>
            ) : null}
           
            { fromLabel(!IS_JP_LANGUAGE, false) }
            {/* 销售价 */}
            <div
              class={['goods-price__main', { 'goods-price__margin-left': !IS_JP_LANGUAGE, 'goods-price__small-size': salePrice.isSmallStyle }, ...(salePrice.classNames || [])]}
              {...{ directives: [{ name: 'ada', value: { level: 1, pos: 0 } }] }}
              aria-label={salePrice.ada}
              role="text"
              style={styleMap.mainPrice}
              domPropsInnerHTML={salePrice.text}
            ></div>
            { fromLabel(IS_JP_LANGUAGE, true) }
            <ClientOnly>
              { showPopoverEl && realtimeReady ? <SPopover
                value={showInclusiveTips}
                show-close-icon={true}
                append-to-body={false}
                trigger-type="user"
                placemen={placemen}
                style="align-self: center;"
              >
                <div style="display: inline">
                  {showInclusiveTips ? <p>{content}</p> : null}
                  {showInclusiveTips && subContent ? <p style="font-weight: 400;width: 5.8133rem;white-space:normal;">{subContent}</p> : null}
                </div>
                <i
                  slot="reference"
                  class="suiiconfont sui_icon_info_12px_2 inclusive-tip"
                  style={{ 'color': isBeltTypeBrand ? '#FFF' : '' }}
                  vOn:click_stop={() => {
                    listeners?.['inclusiveClick']?.()
                  }}
                ></i>
              </SPopover> : null }
            </ClientOnly>
          </div>

          { complianceModeDe && deDiscountInfo.retailPrice.show ?
            <div
              class="goods-price__saleAndDiscountWraper"
              style={{
                ...bigSmallPriceStyles.discountContainer,
                ...styleMap.discountContainer
              }}
            >
              <div
                {...{ directives: [{ name: 'ada', value: { level: 1, pos: 0 } }] }}
                class="goods-price__sale"
                aria-label={deDiscountInfo.retailPrice.value}
                role="text"
                style={{
                  ...styleMap.discountPrice,
                  ...bigSmallPriceStyles.discountPrice
                }}
              >
                <span>{ language.SHEIN_KEY_PWA_31306 }&ensp;</span>
                <span class="goods-price__sale_line-through">{deDiscountInfo.retailPrice.value}</span>
              </div>
              <div
                aria-hidden="true"
                class={['goods-price__discount', deDiscountInfo.retailDiscountPercent.extendClass]}
                style={styleMap.discountPercent}
              >
                {parsePercentText(`${lang_rp != 'il' ? '-' : ''}${deDiscountInfo.retailDiscountPercent.value}%`)}
              </div>
            </div> : null
          }

          { complianceModeDe && deDiscountInfo.suggestedSalePrice.show ?
            <div
              class="goods-price__saleAndDiscountWraper"
              style={{
                ...bigSmallPriceStyles.discountContainer,
                ...styleMap.discountContainer,
                width: deDiscountInfo.retailPrice.show && deDiscountInfo.suggestedSalePrice.show ? '9rem' : '',
                margin: deDiscountInfo.retailPrice.show && deDiscountInfo.suggestedSalePrice.show ? '.2133rem 0 .1067rem 0' : '',
                'margin-bottom': isBeltTypeBrand ? '0' : ''
              }}
            >
              <div
                {...{ directives: [{ name: 'ada', value: { level: 1, pos: 0 } }] }}
                class="goods-price__sale"
                aria-label={deDiscountInfo.suggestedSalePrice.value}
                role="text"
                style={{
                  ...styleMap.discountPrice,
                  ...bigSmallPriceStyles.discountPrice
                }}
              >
                <span>{ language.SHEIN_KEY_PWA_31307 }&ensp;</span>
                <span class="goods-price__sale_line-through">{deDiscountInfo.suggestedSalePrice.value}</span>
              </div>
              {deDiscountInfo.suggestedDiscountPercent.show ? (
                <div
                  aria-hidden="true"
                  class={['goods-price__discount', deDiscountInfo.suggestedDiscountPercent.extendClass]}
                  style={styleMap.discountPercent}
                >
                  {parsePercentText(`${lang_rp != 'il' ? '-' : ''}${deDiscountInfo.suggestedDiscountPercent.value}%`)}
                </div>
              ) : null}
            </div> : null 
          }

          { !complianceModeDe ?
            <div
              class="goods-price__saleAndDiscountWraper"
              style={{
                ...bigSmallPriceStyles.discountContainer,
                ...styleMap.discountContainer
              }}
            >
              {/* 合规划线价 */}
              {discountInfo.suggestedSalePrice.show && !discountInfo.suggestedSalePrice.tip ? (
                <SuggestedSalePrice 
                  class="goods-price__sale"
                  style={{
                    'margin-right': hasEstimated ? '0.2162rem' : '',
                    'margin-left': '0.1081rem'
                  }}
                  is-belt-type-brand={isBeltTypeBrand}
                  suggested-sale-price={discountInfo.suggestedSalePrice}
                />
              ) : null}
              {/* 划线价 */}
              {discountInfo.retailPrice.show ? (
                <div
                  {...{ directives: [{ name: 'ada', value: { level: 1, pos: 0 } }] }}
                  class="goods-price__sale"
                  aria-label={discountInfo.retailPrice.value}
                  role="text"
                  style={{
                    ...styleMap.discountPrice,
                    ...bigSmallPriceStyles.discountPrice
                  }}
                >
                  <span class="goods-price__sale_line-through">{discountInfo.retailPrice.value}</span>
                </div>
              ) : null}
              {/* 折扣标记 */}
              {discountInfo.discountPercent.show ? (
                <div
                  aria-hidden="true"
                  class={['goods-price__discount', discountInfo.discountPercent.extendClass]}
                  style={styleMap.discountPercent}
                >
                  {parsePercentText(`${lang_rp != 'il' ? '-' : ''}${discountInfo.discountPercent.value}%`)}
                </div>
              ) : null}
              {/* 合规划线价-波兰 */}
              {discountInfo.suggestedSalePrice.show && !!discountInfo.suggestedSalePrice.tip ? (
                <SuggestedSalePrice 
                  style={{
                    'margin-right': hasEstimated ? '0.2162rem' : '',
                    'margin-left': '0.0541rem'
                  }}
                  is-belt-type-brand={isBeltTypeBrand}
                  suggested-sale-price={discountInfo.suggestedSalePrice}
                />
              ) : null}
            </div> : null
          }

          {showOnlineVipTags ? <div class="goods-price__tags-holder"></div> : null}
          {showOnlineVipTags ? (
            <div class="goods-price__tags-padding">
              {exclusiveInfo.showLocation === 'line' && (
                <ExclusiveTag
                  info={{
                    PUBLIC_CDN_rp,
                    text: exclusiveInfo.exclusiveText,
                    descText: exclusiveInfo.exclusiveDescText,
                    isRw: IS_RW,
                    IMG_LINK: IMG_LINK,
                    showVipExplaination: showVipExplaination,
                    amountWithSymbol: exclusiveInfo.amountWithSymbol,
                  }}
                  language={language}
                  vOn:showModal={(isShow) => {
                    listeners['showExplainationModal'](isShow)
                    exclusiveTagShowModalAnalysis(isShow)
                  }}
                ></ExclusiveTag>
              )}
              {/* {showPaidVipTag && !!paidTagText && (
                <VipPaidTag
                  vOn:clickTag={() =>
                    handleClick({
                      paidVipData,
                      isSheinClubUser,
                      analysisData: {
                        prime_level,
                        total_saving,
                        location: 'page',
                        from: 1,
                      },
                    })
                  }
                  info={{ PUBLIC_CDN_rp, text: paidTagText }}
                ></VipPaidTag>
              )}
              {showVipUnpaidTag && !!unpaidProps?.price && (
                <VipUnpaidTag
                  vOn:clickTag={() =>
                    handleClick({
                      paidVipData,
                      isSheinClubUser,
                      analysisData: {
                        prime_level,
                        total_saving,
                        location: 'page',
                        from: 1,
                      },
                    })
                  }
                  info={{ PUBLIC_CDN_rp, text: unpaidProps.text, price: unpaidProps.price }}
                ></VipUnpaidTag>
              )} */}
            </div>
          ) : null}
          {hasEstimated && !getEstimatedInfo.hideNothreWithColldisplayrest ? (
            <div class="goods-price__estimated-tags-padding">
              <EstimatedTag
                analysis-data={{
                  location: 'page',
                }}
                has-link={true}
                language={language}
                price-update={+goodsId}
                estimated-info={getEstimatedInfo}
                promotion-info={promotionInfo}
                is-belt-type={isBeltType}
                is-belt-type-brand={isBeltTypeBrand}
                is-floor-type={isFloorType}
                scene="prices"
                vOn:clickLink={listeners?.['clickLink']}
              />
            </div>
          ) : null}
        </div>
        {showFrom ? (
          <span aria-hidden="true" class="j-price-from">
            <span></span>
          </span>
        ) : null}
      </div>
    )
  },
}
</script>

<style lang="less">
.goods-price {
  &__left {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    max-width: 100%;
  }

  &__special-flash-content {
    display: none;
    color: #fa6338;
    font-size: 12px;
    font-weight: 700;
  }

  &__left-special-flash {
    flex-direction: column;
    background: linear-gradient(270deg, #ffffff 17.52%, rgba(255, 255, 255, 0) 64.13%),
      linear-gradient(180deg, #ffeae5 0%, rgba(255, 246, 243, 0) 100%);
    padding-top: 0.2133rem;
    padding-left: 0.32rem;
    margin-left: -0.32rem;
    margin-top: -0.2133rem;

    .goods-price__special-flash-content {
      display: block;
    }
  }

  &__basic {
    display: flex;
    align-items: center;
    margin-right: 0.2133rem;
    flex-wrap: wrap;
    max-width: 100%;
  }

  &__basic-special-flash {
    flex-wrap: wrap;
  }

  &__prices {
    position: relative;
    display: flex;
    align-items: flex-end;
    line-height: 1;
    .j-promotion-icon-ctn { /* stylelint-disable-line selector-class-pattern */
      align-self: center;
    }
  }

  &__prices-has-estimated {
    margin-right: 0.1067rem;

    .goods-price__main {
      line-height: 20px;
    }
  }

  .icon-limitedoff-new,
  .sui_icon_time_15px {
    color: #fa6338 /*rw:#FF696E*/;
    .font-dpr(36px);
    .margin-r(0.04rem);
  }

  &__from {
    .flexbox();
    .align-center();
    .font-dpr(24px);
    font-weight: 400;
    direction: ltr /*rtl:ignore*/;
    color: #222;
    margin-bottom: 2px;
  }
  &__margin-left {
    .margin-l(0.053333rem);
  }

  &__main {
    .flexbox();
    .align-center();
    .font-dpr(36px);
    font-weight: bold;
    direction: ltr /*rtl:ignore*/;
    color: #222;
    &.goods-price__small-size{
      .font-dpr(24px);
      line-height: 16px
    }
  }

  &__from_has-discount {
    color: @sui_color_discount;
  }

  &__from_special-flash {
    color: @sui_color_discount;
    line-height: 1.2;
  }

  &__from_paid-vip {
    color: #873c00;
  }

  .S-popover__main-content {
    white-space: nowrap;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
  }

  .inclusive-tip {
    display: inline-block;
    color: #959595;
    margin-left: 0.0667rem;
  }
  // 划线价和折扣百分比一起换行
  &__saleAndDiscountWraper {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  &__sale {
    display: inline-block;
    .font-dpr(20px);
    color: #666;
    .margin-l(.0533rem);
    line-height: 1;
  }
  &__sale_line-through {
    text-decoration: line-through;
  }

  &__discount {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: .32rem;
    .font-dpr(20px);
    background: #fff;
    color: #fa6338;
    font-weight: 400;
    font-style: normal;
    border: 1px solid #fa6338;
    border-radius: 2px;
    .margin-l(0.1rem);
    padding: 1px 2px;
    min-width: 0.8533rem;
    /*rw:begin*/
    font-family: 'Adieu';
    color: @sui_color_white;
    background: #fc4070;
    font-weight: 700;
    .font-dpr(18px);
    padding: 0 0.17333rem;

    &.price-new__discount {
      background: #fa6338;
      color: @sui_color_white;
      /*rw:begin*/
      background: #fc4070;
    }

    &.flash {
      background: #fcd364;
      color: #333;
      border: none;
      border-radius: 0px;
      /*rw:begin*/
      color: @sui_color_white;
      background: @sui_color_flash;
      border: 1px solid @sui_color_flash;
    }

    &.special-flash {
      background: #fa6338;
      color: #fff;
      border: none;
      border-radius: 0px;
    }
  }

  .goods-price__tags-holder {
    width: 0.1067rem;
  }

  &__tags-padding {
    padding: 0.1067rem 0;
  }

  &__estimated-tags-padding {
    padding: 0.1067rem 0;
    max-width: 100%;
  }
}
</style>
