<script lang="jsx">
import { mapGetters, mapState, mapMutations } from 'vuex'
import FindMyShadeInstance from 'public/src/pages/goods_detail/components/find_shade/index.js'
import { expose } from 'public/src/pages/common/analysis/directive.js'
import ColorTitle from './ColorTitle.vue'
import ColorV2 from './ColorV2.vue'

export default {
  name: 'ColorBox',
  directives: { expose },
  components: {
    ColorTitle,
    ColorV2,
  },
  props: {
    fixedRatio: {
      type: String,
      default: ''
    },
    // Apollo 控制补图
    isSupportCropImage: {
      type: Boolean,
      default: false
    },
    switchColor: {
      type: Function,
      required: true
    },
  },
  computed: {
    ...mapGetters('newProductDetail/ColorBox', ['colorBoxInfo', 'isSkcPrepose']),
    ...mapState('newProductDetail/ColorBox', ['lastClickColor']),
    ...mapState('newProductDetail/common', ['loading']),
    getaAnaData(){
      return { 
        name: 'expose', 
        value: { 
          id: '1-6-1-31', 
          data: { 
            location: 'page',
            image_tp: 'small',
            is_front: 1
          }
        } 
      }
    }
  },
  mounted () {
    // find my shade 入口曝光
    if (this.colorBoxInfo.isShowFindMyShadeEntry) {
      daEventCenter.triggerNotice({
        daId: '1-6-4-32'
      })
    } 
  },
  methods: {
    ...mapMutations('newProductDetail/common', ['updateQuickAddState', 'updateQuickAddLargeImgMode']),
    ...mapMutations('newProductDetail/ColorBox', ['updateAllgoodsImg']),
    clickShowFindMyShade() {
      FindMyShadeInstance.show({ ruleId: this.colorBoxInfo.findShadeRuleId, urlQuery: 'main_attr=' + this.colorBoxInfo.mainAttribute, urlFilter: /&?attr_ids=(\d|_)+/g })
    },
    // 点击大图模式切换，直接打开快速加车弹窗大图列表模式
    onLargeImageSwitch() {
      const { colorBoxInfo, updateQuickAddState, updateQuickAddLargeImgMode } = this
      const { supportLargeImageList } = colorBoxInfo
      if (!supportLargeImageList) {
        return
      }
      updateQuickAddLargeImgMode(true)
      updateQuickAddState(true)

      daEventCenter.triggerNotice({ daId: '1-6-1-101' })
    },
  },
  render() {
    const {
      detail,
      language,
      colorConf,
      LAZY_IMG,
      WEB_CLIENT,
      goodsDesc,
      colorBlockText,
      supportLargeImageList,
      isShowFindMyShadeEntry,
      colorType,
      colorsInfo,
      showGoodsDesc,
      IS_RW,
      PUBLIC_CDN_rp,
      hotGoodSnList,
      loadConf,
      goodsMainAttPicInfo
    } = this.colorBoxInfo

    const renderColorSelectBox = () => {
      return (
        <ColorV2
          language={language}
          lazyImg={LAZY_IMG}
          colorConf={colorConf}
          detail={detail}
          webClient={WEB_CLIENT}
          goodsDesc={goodsDesc}
          colorsInfo={colorsInfo}
          eventCategory={'商品详情页'}
          from={'page'}
          colorType={colorType}
          supportLargeImageList={supportLargeImageList}
          showGoodsDesc={showGoodsDesc}
          isRw={IS_RW}
          publicCdn={PUBLIC_CDN_rp}
          hotGoodSnList={hotGoodSnList}
          loadConf={loadConf}
          fixedRatio={this.fixedRatio}
          isSupportCropImage={this.isSupportCropImage}
          switchColor={this.switchColor}
          handleAllgoodsImg={this.updateAllgoodsImg}
          loading={this.loading}
          lastClickColor={this.lastClickColor}
          goodsMainAttPicInfo={goodsMainAttPicInfo}
          isSkcPrepose={this.isSkcPrepose}
        />
      )
    }

    return (
      <div 
        class="goods-color"
        {...{ directives: [this.getaAnaData] }}
      >
        {/* 色块标题 */}
        <ColorTitle
          isShowColorMoreIcon={detail?.mainSaleAttrShowMode == 2}
          language={language}
          colorBlockText={colorBlockText}
          supportLargeImageList={supportLargeImageList}
          isShowFindMyShadeEntry={isShowFindMyShadeEntry}
          largeImageSwitch={this.onLargeImageSwitch}
          clickShowFindMyShade={this.clickShowFindMyShade}
        />
        {/* 色块主体 */}
        { renderColorSelectBox() }
      </div>
    )
  }
}

</script>

<style lang="less">
.goods-color {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .selected-color {
      display: inline-flex;
      align-items: center;
      // line-height: 1;
      line-height: normal;
      margin-bottom: 4px;
    }

    .color-block, i {
      display: inline-block;
      font-style: normal;
      font-weight: 700;
      .font-dpr(28px);

      /* rw:begin */
      font-family: Adieu;
    }
  }

  &__shade {
    display: inline-block;
    color: #1860A7;
    .font-dpr(24px);
    text-decoration: none;
  }
}
</style>
