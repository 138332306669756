import { ref, computed, onMounted, defineAsyncComponent, getCurrentInstance } from 'vue';
import BHeaderCart from '@shein-aidc/bs-header-cart-mobile-vue2';
import '@shein-aidc/bs-header-cart-mobile-vue2/style.css'

import { web_cart_entry_click } from '@/public/src/pages/cartNew/utils/metricTagsConfig.js';
import { markPoint } from '@/public/src/services/mark/index.js';
import ControlAsync from '@/public/src/pages/components/common_header/utils/controlAsync.js';
export default {
  __name: 'index',
  props: {
    themeVars: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    placement: {
      type: String,
      default: 'bottom-end' // top-start
    },
    offsetY: {
      type: Number,
      default: undefined
    },
    loc: {
      type: String,
      default: 'buttonbar'
    }
  },
  setup(__props) {
    var props = __props;
    var OldCart = defineAsyncComponent(function () {
      return import('@/public/src/pages/components/common_header/cart/index.vue');
    });
    var headerAnalysis = new ControlAsync({
      file: function file() {
        return import(/* webpackChunkName: "header-analysis" */'@/public/src/pages/components/common_header/analysis/index.js');
      }
    }).run();
    var useRouter = function useRouter() {
      var vm = getCurrentInstance();
      if (!vm) throw new Error('must be called in setup');
      return vm.proxy.$router;
    };
    var useStore = function useStore() {
      var vm = getCurrentInstance();
      if (!vm) throw new Error('must be called in setup');
      return vm.proxy.$store;
    };
    var router = useRouter();
    var store = useStore();
    var isNewHead = computed(function () {
      return store.state.IS_NEW_HEAD;
    });
    var topTextColor = computed(function () {
      return props.themeVars.iconColor;
    });
    var iconSize = computed(function () {
      return (props.themeVars.iconSize || 24) + 'px';
    });
    var commonHeaderColorVariable = computed(function () {
      return {
        '--shein-common-header-icon-color': props.themeVars.iconColor,
        '--shein-common-header-badge-border-color': props.themeVars.iconColor ? '#fff' : ''
      };
    });
    var onClick = function onClick(_ref) {
      var extraData = _ref.extraData;
      markPoint('toNextPageClick', 'public');
      headerAnalysis().then(function (res) {
        return res.clickBag(extraData);
      }).finally(function () {
        router.push(`${gbCommonInfo.langPath}/cart`);
      });
    };
    var onClickTip = function onClickTip(_ref2) {
      var extraData = _ref2.extraData;
      headerAnalysis().then(function (res) {
        return res.clickBagTip(extraData);
      }).finally(function () {
        router.push(`${gbCommonInfo.langPath}/cart`);
      });
    };
    var onExpose = function onExpose(_ref3) {
      var extraData = _ref3.extraData;
      headerAnalysis().then(function (res) {
        return res.exposeBag(extraData);
      });
    };
    var onExposeTip = function onExposeTip(_ref4) {
      var extraData = _ref4.extraData;
      headerAnalysis().then(function (res) {
        return res.exposeBagTip(extraData);
      });
    };
    var onBeforeClick = function onBeforeClick() {
      web_cart_entry_click('3');
      markPoint('toNextPageClick', 'public');
    };
    var refBHeaderCart = ref(null);
    onMounted(function () {
      var _refBHeaderCart$value;
      (_refBHeaderCart$value = refBHeaderCart.value) === null || _refBHeaderCart$value === void 0 || _refBHeaderCart$value.onExpose();
    });
    return {
      __sfc: true,
      OldCart,
      headerAnalysis,
      useRouter,
      useStore,
      router,
      store,
      props,
      isNewHead,
      topTextColor,
      iconSize,
      commonHeaderColorVariable,
      onClick,
      onClickTip,
      onExpose,
      onExposeTip,
      onBeforeClick,
      refBHeaderCart,
      BHeaderCart
    };
  }
};