<script lang="jsx">
import PlaceholderGoodsSize from './components/PlaceholderGoodsSize'
import SizeBox from '../SizeBox'
import NewSizeBox from 'public/src/pages/goods_detail_v2/innerComponents/top/Size/SizeBox.vue'
import SaleAttrsFoldComp from '../SaleAttrsFold/index.vue'
import Quantity from './components/Quantity'
import ClientOnly from 'vue-client-only'

import { daEventCenter } from 'public/src/services/eventCenter/index'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

daEventCenter.addSubscriber({ modulecode: '1-6-1' })
import { Toast } from '@shein/sui-mobile'


export default {
  name: 'TopOther',
  components: {
    Quantity,
    PlaceholderGoodsSize,
    SizeBox,
    NewSizeBox,
    CatSelectionTagEnter: () =>
      import(
        /* webpackChunkName: "CatSelectionTagEnter" */ 'public/src/pages/goods_detail/components/middle/innerComponents/MiddleAttr/components/CatSelectionTagEnter.vue'
      ),
    CatSelectionTagEnterV2: () => import(
      /* webpackChunkName: "CatSelectionTagEnterV2" */ 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/CatSelectionTagEnter/index.vue'
    ),
    Gifting: () => import(
      /* webpackChunkName: "Gifting" */ 'public/src/pages/goods_detail_v2/innerComponents/recommend/Gifting/index.vue'
    ),
  },
  data() {
    return {
      // rankingLink: '',
      showSelectionDrawer: false,
      giftListLoding: false,
    }
  },
  computed: {
    ...mapState('productDetail', ['MAIN_BFF_APOLLO']),
    ...mapState('productDetail/gift', ['gift_list', 'gift_selected_status', 'gift_selected_list', 'gift_lowest_price', 'gift_selected_prices', 'gift_batch_add_status']),
    ...mapGetters('productDetail/common', ['category_selection_floor_bo', 'category_selection_floor_new']),

    // todo后续迁移新数据结构
    // ...mapState('productDetail', ['topRankingInfo']),
    ...mapState('newProductDetail/common', ['fixedRatio', 'pageCommonInfo']),
    ...mapGetters('newProductDetail/common', ['language', 'productItemsLanguage', 'detail', 'pageCommonInfo', 'saleAttrsFoldAb', 'getSkcEstimatedInfo']),
    ...mapGetters('newProductDetail/TopOther', [
      'showCatSelectionTagTop',
      'topOtherBase',
      'showQuantity',
      // 'abtRankAggrega',
      // 'RankAggrega',
      'showSizePlaceHolder',
      'cacheSaleAttrList'
    ]),
    ...mapGetters('newProductDetail/SaleAttrsFold', ['compShowConfig']),
    ...mapGetters('newProductDetail', ['fsAbt', 'isPerformance', 'cccCatSelectionTag']),
    giftInfo() {
      return {
        giftList: this.gift_list || [],
        giftSelectedStatus: this.gift_selected_status || false,
        giftSelectedList: this.gift_selected_list || [],
        giftLowestPrice: this.gift_lowest_price || '',
        giftSelectedPrices: this.gift_selected_prices || '',
        productItemsLanguage: this.productItemsLanguage || {},
      }
    }
  },
  methods: {
    ...mapMutations('productDetail/gift', ['update_gift_selected_list']),
    ...mapActions('productDetail/gift', ['get_selected_gift_prices']),
    handleSelectionDrawer(status) {
      if (status) {
        daEventCenter.triggerNotice({ daId: '1-6-4-72' })
        daEventCenter.triggerNotice({ daId: '1-6-4-73' })
      }
      this.showSelectionDrawer = status
    },
  },
  render() {
    // common
    const {
      topOtherBase = {},
      giftInfo,
      gift_batch_add_status: giftBatchAddStatus,
      showQuantity,
      showSizePlaceHolder,
      showCatSelectionTagTop,
      cccCatSelectionTag,
      pageCommonInfo,
      saleAttrsFoldAb,
      showSelectionDrawer,
      handleSelectionDrawer,
      getSkcEstimatedInfo
    } = this
    const { language } = topOtherBase

    const renderQty = () => {
      if (saleAttrsFoldAb && !this.compShowConfig?.showMallAndQty) return null
      if (getSkcEstimatedInfo?.isSatisfiedBuyMultiple || showQuantity) {
        return <Quantity language={language} /> 
      }
    }

    const renderCatSelectionTag = () => {
      if (this.MAIN_BFF_APOLLO?.v2) {
        return this.category_selection_floor_new ? <CatSelectionTagEnterV2
          isNewStyle={true}
          categorySelectionFloorBo={this.category_selection_floor_bo} 
        /> : null
      }
      return showCatSelectionTagTop ? <CatSelectionTagEnter
        quality-floor="NEW"
        tag-data={cccCatSelectionTag}
        page-common-info={pageCommonInfo}
        show-selection-drawer={showSelectionDrawer}
        language={language}
        vOn:handleSelectionDrawer={handleSelectionDrawer}
      /> : null
    }
    const showNewSizeBox = this.MAIN_BFF_APOLLO?.v6 && !this.showSizePlaceHolder

    const handleSelectGiftList = async ({ selectedStatus, selectedProducts, getGiftPrice = false }) => {
      this.update_gift_selected_list({
        gift_selected_status: selectedStatus,
        gift_selected_list: selectedProducts,
      })
      if(getGiftPrice && selectedProducts.length > 0) {
        this.giftListLoding = true
        let result = await this.get_selected_gift_prices()
        this.giftListLoding = false
        let { info: { bundlePrice = {} } = {}, code } = result || {}
        if(code === '0') {
          Toast(language.SHEIN_KEY_PWA_33564 || 'Add Successful!')
        }
        daEventCenter.triggerNotice({
          daId: '1-6-1-250',
          extraData: {
            result: code === '0' ? 1 : 0,
            reason: code === '0' ? '' : code,
            select_gift_num: selectedProducts.length,
            select_gift_price: bundlePrice?.amountWithSymbol || '',
          }
        })
      }
    }
    return (
      <div class={{
        'goods-detail__top-other': true,
        'goods-detail__top-otherFold': saleAttrsFoldAb,
      }}>
        {/* 旧尺码组件 <Sizes></Sizes> 对照 */}
        {/* 新尺码组件 <SizeBox />*/}
        {showNewSizeBox ? <NewSizeBox /> : null}
        {!showNewSizeBox && saleAttrsFoldAb ? <ClientOnly><SaleAttrsFoldComp /></ClientOnly> : null}
        {!showNewSizeBox && !saleAttrsFoldAb && (showSizePlaceHolder ? <PlaceholderGoodsSize size-info={this.cacheSaleAttrList}/> : <SizeBox />)}
        { 
          giftInfo?.giftList.length > 0 &&
          <Gifting 
            language={language}
            giftInfo={giftInfo}
            giftBatchAddStatus={giftBatchAddStatus}
            loading={this.giftListLoding}
            addSource="detailPage"
            vOn:handleSelectGiftList={handleSelectGiftList}
          /> 
        }
        {
          !!giftInfo?.giftList.length && (getSkcEstimatedInfo?.isSatisfiedBuyMultiple || showQuantity) &&
          <div class="gifting-line"></div>
        }
        { renderQty() }
        { renderCatSelectionTag() }
      </div>
    )
  },
}
</script>

<style lang="less">
.goods-detail__top-other {
  padding-bottom: .27rem;
}
.goods-detail__top-otherFold {
  padding-bottom: .27rem;
}
.gifting-line {
  height: .0133rem;
  background: #e5e5e5;
  margin-top: .21rem;
}
</style>
