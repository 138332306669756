var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"sizeRecommendRef",staticClass:"recommendEntrance-size",class:{
    'fold-attr-recommendEntrance-size': _vm.showSaleAttrFold,
    'recommendEntrance-size-self': _setup.sizeRecommendType != 2 && (_setup.showCheckMySizeEntrance || _setup.showSizeGuideEntry)
  }},[(_setup.showCheckMySizeExternal)?_c('div',{staticClass:"j-sa-check-size j-btn-check-my-size-wrapper"}):_vm._e(),_vm._v(" "),(_setup.showCheckMySizeEntrance)?_c('a',{staticClass:"link-check-my-size",class:{ 'link-check-my-size-planA': true },on:{"click":_setup.selfSizeRecommendClick}},[(_vm.showSaleAttrFold)?_c('Icon',{attrs:{"name":"sui_icon_check_my_size_14px","color":"#000"}}):_vm._e(),_vm._v(" "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.recommendSizeTextDom)}}),_vm._v(" "),_c('span',{staticClass:"icon suiiconfont sui_icon_more_right_12px_2"})],1):_vm._e(),_vm._v(" "),(_setup.showSizeGuideEntry)?_c('a',{directives:[{name:"expose",rawName:"v-expose",value:({ id: '1-6-1-137', data: { from: _vm.isFromPopup ? 2 : 1 } }),expression:"{ id: '1-6-1-137', data: { from: isFromPopup ? 2 : 1 } }"},{name:"tap",rawName:"v-tap",value:({ id: '1-6-1-138', data: { from: _vm.isFromPopup ? 2 : 1 } }),expression:"{ id: '1-6-1-138', data: { from: isFromPopup ? 2 : 1 } }"}],staticClass:"link-check-my-size j-push-history-hash",class:{ 
      'link-check-my-size-planA': true,
      'has-left-gap': (_setup.showSizeGuideEntry && _setup.showCheckMySizeEntrance) || _setup.showCheckMySizeExternal,
    },attrs:{"state":"sizeGuideSizeEntry"},on:{"click":_setup.handleSizeGuide}},[(_vm.showSaleAttrFold)?_c('Icon',{attrs:{"name":"sui_icon_sizeguide_14px","color":"#000"}}):_vm._e(),_vm._v(" "),_c('p',[_vm._v("\n      "+_vm._s(_vm.language.SHEIN_KEY_PWA_15005)+"\n    ")]),_vm._v(" "),_c('span',{staticClass:"icon suiiconfont sui_icon_more_right_12px_2"})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }